import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContext, useCallback } from 'react';
import {
  makeApiProvider,
  useApiQuery,
  useSuspenseApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  CreateKycDocumentParams,
  CreateResidentParams,
  CreateResidentResult,
  GetKycDocumentsResult,
  IResidentPhoenixApi,
  KYCDocument,
  UpdateResidentFamilyMemberDto,
} from './api';
import { ResidentPhoenixApi } from './api';
import type {
  FamilyMemberPhoenix,
  GetAllResidentsResult,
  ImportFamilyMemberPhoenix,
  ImportFamilyMemberResponsePhoenix,
  ShowResidentDto,
} from '@pflegenavi/shared/api';
import { PAYMENT_STATUS_KEY } from '@pflegenavi/shared/constants';
import {
  invalidateResident,
  invalidateResidentsKeys,
} from '../resident/queryKeys';
import { PAYMENTS_INFO_KEY } from '../payment-phoenix';
import {
  CREATE_KYC_DOCUMENT,
  GET_KYC_DOCUMENT,
  GET_KYC_DOCUMENTS,
  RESIDENTS_PHOENIX_KEY,
  SHOW_RESIDENT_KEY,
} from './queryKeys';

export type { InitialBalance, KYCDocument } from './api';

const ApiContext = createContext<IResidentPhoenixApi | undefined>(undefined);
const {
  useApi: useResidentPhoenixApi,
  ApiProvider: ResidentPhoenixApiProvider,
} = makeApiProvider({
  name: 'ResidentPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new ResidentPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useResidentPhoenixApi,
  ResidentPhoenixApiProvider,
  ApiContext as ResidentPhoenixApiContext,
  IResidentPhoenixApi,
};

const IMPORT_FAMILY_MEMBERS_KEY: QueryKey = ['import-family-members-key'];
const UPDATE_RESIDENT_KEY: QueryKey = ['update-resident-key'];

const DELETE_RESIDENT_FAMILY_MEMBER_KEY = (residentId: string) => [
  'delete-resident-family-member',
  residentId,
];

const UPDATE_RESIDENT_FAMILY_MEMBER_KEY = (residentId: string) => [
  'update-resident-family-member',
  residentId,
];

const RESIDENT_STALE_TIME = 1000 * 60 * 5; // 5 minutes

const selectResidents = (
  dataIn: GetAllResidentsResult
): GetAllResidentsResult => {
  const data = dataIn.data.map((resident) => ({
    ...resident,
    balance: {
      ...resident.balance,
      threshold_due_date:
        resident.balance.threshold_due_date &&
        new Date(resident.balance.threshold_due_date),
      negative_due_date:
        resident.balance.negative_due_date &&
        new Date(resident.balance.negative_due_date),
    },

    resident_family_member_balances:
      resident.resident_family_member_balances.map((balance) => ({
        ...balance,
        threshold_due_date:
          balance.threshold_due_date && new Date(balance.threshold_due_date),
        negative_due_date:
          balance.negative_due_date && new Date(balance.negative_due_date),
      })),
    nursing_home_residents: {
      ...resident.nursing_home_residents,
      entry_date: new Date(resident.nursing_home_residents.entry_date),
      exit_date:
        resident.nursing_home_residents.exit_date &&
        new Date(resident.nursing_home_residents.exit_date),
    },
    settlement: {
      ...resident.settlement,
      settlement_date:
        resident.settlement?.settlement_date &&
        new Date(resident.settlement.settlement_date),
    },
  }));

  return {
    ...dataIn,
    data: data,
  };
};

/**
 * Heavily based on useResidents. Since we need to add additionalFields without breaking other queries
 * @param nursingHomeId
 * @param options
 */
export const useAllResidentsPhoenix = (
  nursingHomeId: string | undefined,
  options?: Omit<
    UseQueryOptions<GetAllResidentsResult, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<GetAllResidentsResult, unknown> => {
  const result = useApiQuery(
    useResidentPhoenixApi,
    RESIDENTS_PHOENIX_KEY(nursingHomeId ?? ''),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getAllResidents({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select: selectResidents,
    }
  );

  return result;
};

/**
 * Heavily based on useResidents. Since we need to add additionalFields without breaking other queries
 * @param nursingHomeId
 * @param options
 */
export const useAllResidentsPhoenixSuspense = (
  nursingHomeId: string,
  options?: Omit<
    UseSuspenseQueryOptions<GetAllResidentsResult, unknown>,
    'queryFn' | 'queryKey'
  >
): UseSuspenseQueryResult<GetAllResidentsResult, unknown> => {
  const result = useSuspenseApiQuery(
    useResidentPhoenixApi,
    RESIDENTS_PHOENIX_KEY(nursingHomeId),
    (api) => {
      return api.getAllResidents({ params: { nursingHomeId } });
    },
    {
      staleTime: RESIDENT_STALE_TIME,
      ...options,
      select: selectResidents,
    }
  );

  return result;
};

export const useCreateResidentPhoenix = (): UseMutationResult<
  CreateResidentResult,
  unknown,
  CreateResidentParams
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<CreateResidentResult, unknown, CreateResidentParams>({
    mutationKey: ['CREATE_RESIDENT_PHOENIX'],
    mutationFn: (data) => api.createResident(data),
    onSuccess: (_data, variables) => {
      return invalidateResidentsKeys(
        queryClient,
        variables.body.nursing_home_id
      );
    },
  });
};

export function selectShowResidentDto(data: ShowResidentDto): ShowResidentDto {
  return {
    data: {
      ...data.data,
      resident: {
        ...data.data.resident,
        balance: {
          ...data.data.resident.balance,
          negative_due_date:
            data.data.resident.balance.negative_due_date &&
            new Date(data.data.resident.balance.negative_due_date),
          threshold_due_date:
            data.data.resident.balance.threshold_due_date &&
            new Date(data.data.resident.balance.threshold_due_date),
        },
        birthdate:
          data.data.resident.birthdate &&
          new Date(data.data.resident.birthdate),
        created_on:
          data.data.resident.created_on &&
          new Date(data.data.resident.created_on),
        nursing_home_residents: {
          ...data.data.resident.nursing_home_residents,
          entry_date: new Date(
            data.data.resident.nursing_home_residents.entry_date
          ),
          exit_date:
            data.data.resident.nursing_home_residents.exit_date &&
            new Date(data.data.resident.nursing_home_residents.exit_date),
        },
        resident_family_member_balances:
          data.data.resident.resident_family_member_balances.map((balance) => ({
            ...balance,
            threshold_due_date:
              balance.threshold_due_date &&
              new Date(balance.threshold_due_date),
            negative_due_date:
              balance.negative_due_date && new Date(balance.negative_due_date),
          })),
        settlement: {
          ...data.data.resident.settlement,
          settlement_date:
            data.data.resident.settlement?.settlement_date &&
            new Date(data.data.resident.settlement.settlement_date),
        },
      },
      resident_family_member_balances:
        data.data.resident_family_member_balances.map((balance) => {
          return {
            ...balance,
            threshold_due_date:
              balance.threshold_due_date &&
              new Date(balance.threshold_due_date),
            negative_due_date:
              balance.negative_due_date && new Date(balance.negative_due_date),
          };
        }),
    },
  };
}

export const useShowResidentPhoenix = (
  residentId: string,
  options?: Omit<
    UseQueryOptions<ShowResidentDto, unknown, ShowResidentDto>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<ShowResidentDto, unknown> => {
  const select = useCallback(selectShowResidentDto, []);

  return useApiQuery(
    useResidentPhoenixApi,
    SHOW_RESIDENT_KEY(residentId),
    (api) => {
      return api?.showResident({
        params: {
          residentId,
        },
      });
    },
    {
      ...options,
      select,
      enabled: Boolean(residentId),
    }
  );
};

export const useShowResidentForFamilyMemberPhoenix = (
  residentId: string,
  tenant: string,
  options?: Omit<
    UseQueryOptions<ShowResidentDto, unknown, ShowResidentDto>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<ShowResidentDto, unknown> => {
  const select = useCallback(selectShowResidentDto, []);

  return useApiQuery(
    useResidentPhoenixApi,
    SHOW_RESIDENT_KEY(residentId),
    (api) => {
      return api?.showResidentForFamilyMember({
        params: {
          residentId,
          tenant,
        },
      });
    },
    {
      ...options,
      select,
      enabled: Boolean(residentId) && Boolean(tenant),
    }
  );
};

export const useImportFamilyMembersPhoenix = (): UseMutationResult<
  ImportFamilyMemberResponsePhoenix,
  unknown,
  ImportFamilyMemberPhoenix
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    ImportFamilyMemberResponsePhoenix,
    unknown,
    ImportFamilyMemberPhoenix
  >({
    mutationKey: IMPORT_FAMILY_MEMBERS_KEY,
    mutationFn: (data) => {
      return api.importFamilyMembers({ body: data });
    },
    onSuccess: async (_, variables) => {
      await invalidateResidentsKeys(queryClient, variables.nursing_home_id);
    },
  });
};

export const useAddNewFamilyMemberPhoenix = (
  residentId: string
): UseMutationResult<
  {
    status: 'Created';
  },
  unknown,
  FamilyMemberPhoenix
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();

  return useMutation<
    {
      status: 'Created';
    },
    unknown,
    FamilyMemberPhoenix
  >({
    mutationKey: UPDATE_RESIDENT_KEY,
    mutationFn: (data) => {
      return api.addNewFamilyMember({
        params: { residentId },
        body: {
          data,
        },
      });
    },
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: PAYMENT_STATUS_KEY(residentId),
        }),
        queryClient.invalidateQueries({
          queryKey: PAYMENTS_INFO_KEY(residentId),
        }),
        invalidateResident(queryClient, residentId, undefined),
      ]);
    },
  });
};

export const useResendFamilyMemberWelcomeEmail = (): UseMutationResult<
  void,
  unknown,
  {
    residentId: string;
    familyMemberId: string;
  }
> => {
  const api = useResidentPhoenixApi();
  const result = useMutation<
    void,
    unknown,
    {
      residentId: string;
      familyMemberId: string;
    }
  >({
    mutationKey: ['RESEND_FAMILY_MEMBER_WELCOME_EMAIL'],
    mutationFn: (data) =>
      api.resendFamilyMemberWelcomeEmail({
        params: data,
      }),
  });
  return result;
};

export const useDeleteFamilyMemberForGivenResidentPhoenix = (
  residentId: string
): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    familyMemberId: string;
  }
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      familyMemberId: string;
    }
  >({
    mutationKey: DELETE_RESIDENT_FAMILY_MEMBER_KEY(residentId),
    mutationFn: (data) =>
      api.deleteFamilyMemberForGivenResidentPhoenix({
        params: {
          familyMemberId: data.familyMemberId,
          residentId: residentId,
        },
      }),
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: PAYMENT_STATUS_KEY(residentId),
        }),
        invalidateResident(queryClient, residentId, undefined),
      ]);
    },
  });
};

export const useUpdateFamilyMemberForGivenResidentPhoenix = (
  residentId: string
): UseMutationResult<
  {
    data: UpdateResidentFamilyMemberDto;
  },
  unknown,
  UpdateResidentFamilyMemberDto
> => {
  const api = useResidentPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      data: UpdateResidentFamilyMemberDto;
    },
    unknown,
    UpdateResidentFamilyMemberDto
  >({
    mutationKey: UPDATE_RESIDENT_FAMILY_MEMBER_KEY(residentId),
    mutationFn: (data) =>
      api.updateFamilyMemberForGivenResidentPhoenix({
        params: {
          familyMemberId: data.user_id,
          residentId: residentId,
        },
        body: {
          data: data,
        },
      }),
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: PAYMENTS_INFO_KEY(residentId),
        }),
        queryClient.invalidateQueries({
          queryKey: PAYMENT_STATUS_KEY(residentId),
        }),
        invalidateResident(queryClient, residentId, undefined),
      ]);
    },
  });
};

export const useGetKycDocuments = (
  residentId: string
): UseQueryResult<GetKycDocumentsResult, unknown> => {
  return useApiQuery(
    useResidentPhoenixApi,
    [GET_KYC_DOCUMENTS, residentId],
    (api) => {
      return api.getKycDocuments({ params: { residentId } });
    }
  );
};

export const useGetKycDocument = (
  residentId: string,
  kycDocumentId: string
): UseQueryResult<KYCDocument, unknown> => {
  return useApiQuery(
    useResidentPhoenixApi,
    [GET_KYC_DOCUMENT, residentId, kycDocumentId],
    (api) => {
      return api.getKycDocument({ params: { residentId, kycDocumentId } });
    }
  );
};

export type CreateKycDocumentType = CreateKycDocumentParams['body'];

export const useCreateKycDocument = (
  residentId: string
): UseMutationResult<KYCDocument, unknown, CreateKycDocumentType> => {
  const queryClient = useQueryClient();
  const api = useResidentPhoenixApi();
  return useMutation<KYCDocument, unknown, CreateKycDocumentType>({
    mutationKey: CREATE_KYC_DOCUMENT,
    mutationFn: (data) =>
      api.createKycDocument({
        params: { residentId },
        body: data,
      }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [GET_KYC_DOCUMENTS, residentId],
        }),
      ]);
    },
  });
};
