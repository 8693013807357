import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type { ISettlementPhoenixApi, RecurringItemsChargeParams } from './api';
import { SettlementPhoenixApi } from './api';
import {
  invalidateResident,
  RESIDENT_SETTLEMENT_KEY,
} from '../resident/queryKeys';
import {
  getTransactionsKey,
  RECEIPT_BATCH_LIST_PAGINATED_KEY,
} from '../transaction/queryKeys';

const ApiContext = createContext<ISettlementPhoenixApi | undefined>(undefined);
const {
  useApi: useSettlementPhoenixApi,
  ApiProvider: SettlementPhoenixApiProvider,
} = makeApiProvider({
  name: 'RecurringItemPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new SettlementPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useSettlementPhoenixApi,
  SettlementPhoenixApiProvider,
  ApiContext as SettlementPhoenixApiContext,
};

export const useChargeRecurringItemPhoenix = (
  nursingHomeId?: string
): UseMutationResult<void, unknown, RecurringItemsChargeParams> => {
  const api = useSettlementPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<void, unknown, RecurringItemsChargeParams>({
    mutationKey: ['CHARGE_RECURRING_ITEM'],
    mutationFn: (data) => api.recurringItemsCharge(data),
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: RESIDENT_SETTLEMENT_KEY(variables.params.residentId),
        }),
        invalidateResident(
          queryClient,
          variables.params.residentId,
          nursingHomeId
        ),
        queryClient.invalidateQueries({
          queryKey: getTransactionsKey(variables.params.residentId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_LIST_PAGINATED_KEY({ nursingHomeId }),
        }),
      ]);
    },
  });
  return result;
};
