import type { RangeDateFilter } from '@pflegenavi/shared/utils';
import type { QueryClient, QueryKey } from '@tanstack/react-query';
import { RESIDENTS_PHOENIX_KEY } from '../resident-phoenix/queryKeys';

export const ALL_RESIDENT_BALANCE_KEY = (nursingHomeId?: string): QueryKey => [
  'all-resident-balance',
  nursingHomeId,
];

export const REPORTING_KEY = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): QueryKey => ['reporting', nursingHomeId, { dateRange }];

export const SUMMARY_REPORT_KEY = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): QueryKey => ['summary-report', nursingHomeId, { dateRange }];

export const invalidateAllResidentBalance = async (
  client: QueryClient,
  nursingHomeId?: string
): Promise<void> => {
  await Promise.all([
    ...(nursingHomeId
      ? [
          client.invalidateQueries({
            queryKey: RESIDENTS_PHOENIX_KEY(nursingHomeId),
          }),
        ]
      : []),
    client.invalidateQueries({
      queryKey: ALL_RESIDENT_BALANCE_KEY(nursingHomeId),
    }),
  ]);
};
