import type {
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { createContext, useCallback } from 'react';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  FinanceResult,
  GetPayinsParams,
  GetPayinsResult,
  GetPayoutsParams,
  GetPayoutsResult,
  IReportingPhoenixApi,
} from './api';
import { ReportingPhoenixApi } from './api';

const ApiContext = createContext<IReportingPhoenixApi | undefined>(undefined);
const {
  useApi: useReportingPhoenixApi,
  ApiProvider: ReportingPhoenixApiProvider,
} = makeApiProvider({
  name: 'ReportingPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new ReportingPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useReportingPhoenixApi,
  ReportingPhoenixApiProvider,
  ApiContext as ReportingPhoenixApiContext,
  FinanceResult,
  GetPayoutsResult,
  GetPayoutsParams,
  GetPayinsResult,
  GetPayinsParams,
};

export const getFinanceKey = (nursingHomeId: string | undefined): QueryKey => [
  'GET_FINANCE',
  nursingHomeId,
];

export const useGetFinancePhoenix = ({
  nursingHomeId,
  options,
}: {
  nursingHomeId: string | undefined;
  options?: Omit<
    UseQueryOptions<FinanceResult, unknown>,
    'queryFn' | 'queryKey'
  >;
}): UseQueryResult<FinanceResult, unknown> => {
  return useApiQuery(
    useReportingPhoenixApi,
    getFinanceKey(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('Missing nursing home ID');
      }
      return api.getFinance({
        params: {
          nursing_home_id: nursingHomeId,
        },
      });
    },
    {
      ...options,
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useGetPayoutsPhoenix = (
  params: GetPayoutsParams,
  options?: Omit<
    UseQueryOptions<GetPayoutsResult, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<GetPayoutsResult, unknown> => {
  const select = useCallback((data: GetPayoutsResult) => {
    return {
      ...data,
      data: data.data.map((payout) => ({
        ...payout,
        inserted_at: new Date(payout.inserted_at),
        transferred: payout.transferred
          ? new Date(payout.transferred)
          : undefined,
      })),
    };
  }, []);
  return useApiQuery(
    useReportingPhoenixApi,
    ['GET_PAYOUTS_PHOENIX', params.params],
    (api) => api.getPayouts(params),
    {
      ...(options ?? {}),
      select,
    }
  );
};

export const useGetPayinsPhoenix = (
  params: GetPayinsParams,
  options?: Omit<
    UseQueryOptions<GetPayinsResult, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<GetPayinsResult, unknown> => {
  const select = useCallback((data: GetPayinsResult) => {
    return {
      ...data,
      data: data.data.map((payin) => ({
        ...payin,
        action_timestamp: new Date(payin.action_timestamp),
      })),
    };
  }, []);
  return useApiQuery(
    useReportingPhoenixApi,
    ['GET_PAYINS_PHOENIX', params.params],
    (api) => api.getPayins(params),
    {
      ...(options ?? {}),
      select,
    }
  );
};
