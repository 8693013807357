import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type {
  CreateRecurringItem,
  IRecurringItemPhoenixApi,
  RecurringItem,
} from './api';
import { RecurringItemPhoenixApi } from './api';
import {
  GET_RECURRING_ITEM_RESIDENTS_KEY,
  GET_RECURRING_ITEMS_KEY,
} from '../resident/queryKeys';

const ApiContext = createContext<IRecurringItemPhoenixApi | undefined>(
  undefined
);
const {
  useApi: useRecurringItemPhoenixApi,
  ApiProvider: RecurringItemPhoenixApiProvider,
} = makeApiProvider({
  name: 'RecurringItemPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new RecurringItemPhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useRecurringItemPhoenixApi,
  RecurringItemPhoenixApiProvider,
  ApiContext as RecurringItemPhoenixApiContext,
};

export const useCreateRecurringItemPhoenix = (): UseMutationResult<
  RecurringItem,
  unknown,
  CreateRecurringItem
> => {
  const api = useRecurringItemPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<RecurringItem, unknown, CreateRecurringItem>({
    mutationKey: ['CREATE_RECURRING_ITEM'],
    mutationFn: (data) =>
      api.createRecurringItem({
        body: data,
      }),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: GET_RECURRING_ITEMS_KEY(variables.nursing_home_id),
      });
    },
  });
  return result;
};

export const useRemoveResidentFromRecurringItemPhoenix = (): UseMutationResult<
  RecurringItem,
  unknown,
  {
    recurringItemId: string;
    residentId: string;
    body: {
      charge_resident: boolean;
      end_date: Date;
    };
  }
> => {
  const api = useRecurringItemPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    RecurringItem,
    unknown,
    {
      recurringItemId: string;
      residentId: string;
      body: {
        charge_resident: boolean;
        end_date: Date;
      };
    }
  >({
    mutationKey: ['REMOVE_RESIDENT_FROM_RECURRING_ITEM'],
    mutationFn: (data) =>
      api.removeResidentFromRecurringItem({
        params: data,
        body: data.body,
      }),
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: GET_RECURRING_ITEM_RESIDENTS_KEY(variables.recurringItemId),
      });
    },
  });
  return result;
};
