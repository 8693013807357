import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { QueryKey, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { createContext } from 'react';
import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type { ICareTakerApi } from './api';
import { CareTakerApi } from './api';
import type {
  FirstCheckForm,
  FirstCheckFormChangeset,
  FirstCheckFormResponse,
  FirstCheckFormSubmit,
  FirstCheckFormType,
  FirstCheckFormValidate,
  FirstCheckFormFieldValue,
  FirstCheckFormFieldOption,
} from './type';

export type {
  FirstCheckFormChangeset,
  FirstCheckFormValidate,
  FirstCheckForm,
  FirstCheckFormType,
  FirstCheckFormFieldValue,
  FirstCheckFormFieldOption,
};

const ApiContext = createContext<ICareTakerApi | undefined>(undefined);
const { useApi: useCareTakerApi, ApiProvider: CareTakerApiProvider } =
  makeApiProvider({
    name: 'CareTaker',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) => {
      return new CareTakerApi(tenantId, auth, apiUrl);
    },
  });

export {
  useCareTakerApi,
  CareTakerApiProvider,
  ApiContext as CareTakerApiContext,
};

const FIRST_CHECK_GENERAL_FORM_KEY: QueryKey = ['FIRST_CHECK_GENERAL_FORM'];
const PATCH_FIRST_CHECK_FORM_KEY: QueryKey = ['PATCH_FIRST_CHECK_FORM'];

export const useFirstCheckGeneralForm = (): UseSuspenseQueryResult<
  FirstCheckFormResponse,
  Error
> => {
  const api = useCareTakerApi();
  return useSuspenseQuery({
    queryKey: FIRST_CHECK_GENERAL_FORM_KEY,
    queryFn: () => {
      return api.getFirstCheckGeneralForm();
    },
  });
};

export const useValidateFirstCheckForm = (
  form: FirstCheckFormType
): UseMutationResult<
  {
    changeset: FirstCheckFormChangeset;
  },
  unknown,
  FirstCheckFormValidate
> => {
  const api = useCareTakerApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      changeset: FirstCheckFormChangeset;
    },
    unknown,
    FirstCheckFormValidate
  >({
    mutationKey: PATCH_FIRST_CHECK_FORM_KEY,
    mutationFn: (data) =>
      api.validateFirstCheckForm({
        params: {
          form,
        },
        body: data,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: FIRST_CHECK_GENERAL_FORM_KEY,
      });
    },
  });
};

export const useSubmitFirstCheckForm = (
  form: FirstCheckFormType
): UseMutationResult<
  {
    changeset: FirstCheckFormChangeset;
  },
  unknown,
  FirstCheckFormSubmit
> => {
  const api = useCareTakerApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      changeset: FirstCheckFormChangeset;
    },
    unknown,
    FirstCheckFormSubmit
  >({
    mutationKey: PATCH_FIRST_CHECK_FORM_KEY,
    mutationFn: (data) =>
      api.submitFirstCheckForm({
        params: {
          form,
        },
        body: data,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: FIRST_CHECK_GENERAL_FORM_KEY,
      });
    },
  });
};
