import type {
  CancelManualDepositDto,
  CancelReceiptDto,
  CreateReceiptBatchJobParams,
  CreateReceiptDto,
  FilteredTransactionChangesResultDto,
  GetFilteredBalanceChangesQueryParams,
  GetReceiptBatchesQueryDto,
  GetTotalResidentBalanceQueryParams,
  GetTransactionsPaginatedQueryParams,
  GetTransactionsWithReceiptDto,
  IReceiptType,
  ManualDepositDto,
  PaginatedReceiptQueryDto,
  PaginatedReceiptResult,
  ReceiptBatchGetDto,
  ReceiptBatchJob,
  ReceiptBatchListDto,
  ReceiptBatchPaginatedDto,
  ReceiptFull,
  ReceiptsAndReceiptBatchesDto,
  ReceiptsAndReceiptBatchesQueryParams,
  TotalResidentBalanceResultDto,
  UpdateReceiptBatchCashTransactionGroupDto,
  UpdateReceiptBatchResultDto,
  UpdateTransactionDto,
} from '@pflegenavi/shared/api';
import {
  endpoints,
  ReceiptBatchError,
  ReceiptError,
} from '@pflegenavi/shared/api';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getApiBaseUrl,
  modify,
  superFetch,
} from '@pflegenavi/shared-frontend/platform';
import type { PaginatedResultSet } from '@pflegenavi/shared/utils';
import { generatePath } from '@pflegenavi/frontend/routing';

interface ReceiptWithInactiveServiceProviderError extends Error {
  errorCode: ReceiptError.RECEIPT_WITH_INACTIVE_SERVICE_PROVIDER;
}

export const isReceiptWithInactiveServiceProviderError = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): error is ReceiptWithInactiveServiceProviderError => {
  return (
    'errorCode' in error &&
    error.errorCode === ReceiptError.RECEIPT_WITH_INACTIVE_SERVICE_PROVIDER
  );
};

interface ReceiptBatchWithInactiveServiceProviderError extends Error {
  errorCode: ReceiptBatchError.RECEIPT_BATCH_WITH_INACTIVE_SERVICE_PROVIDER;
}

export const isReceiptBatchWithInactiveServiceProviderError = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): error is ReceiptBatchWithInactiveServiceProviderError => {
  return (
    'errorCode' in error &&
    error.errorCode ===
      ReceiptBatchError.RECEIPT_BATCH_WITH_INACTIVE_SERVICE_PROVIDER
  );
};

export interface ITransactionApi extends Api {
  get(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<ReceiptFull[]>;

  getPaginated(dataIn: {
    params: PaginatedReceiptQueryDto;
  }): Promise<PaginatedReceiptResult>;

  create(data: CreateReceiptDto): Promise<ReceiptFull>;

  update(data: UpdateTransactionDto): Promise<ReceiptFull>;

  cancel(data: CancelReceiptDto): Promise<void>;

  submit(receiptIds: string[]): Promise<
    Array<{
      id: string;
    }>
  >;

  getOne(
    transactionId: string,
    query?: {
      optionalFields?: {
        linkedTransactionGroups?: boolean;
        receiptBatch?: boolean;
      };
    }
  ): Promise<ReceiptFull>;

  getTypes(): Promise<IReceiptType[]>;

  delete(transactionId: string): Promise<void>;

  getUploadUrl(
    receiptId: string,
    fileType: string,
    md5Hash: string
  ): Promise<string | undefined>;

  getReceiptImageUrls(receiptIds: string[]): Promise<
    | {
        [key: string]: string | undefined;
      }
    | undefined
  >;

  getTransactions(
    residentId: string,
    transactionTypes?: string[]
  ): Promise<GetTransactionsWithReceiptDto[]>;

  getTransactionsAsFamilyMember(
    residentId: string,
    transactionTypes?: string[],
    familyMemberId?: string | null
  ): Promise<GetTransactionsWithReceiptDto[]>;

  getTransactionsPaginated(dataIn: {
    params: GetTransactionsPaginatedQueryParams & {
      nursingHomeId: string;
    };
  }): Promise<PaginatedResultSet<GetTransactionsWithReceiptDto>>;

  getReceiptsAndReceiptBatchesPaginated(dataIn: {
    params: ReceiptsAndReceiptBatchesQueryParams & {
      nursingHomeId: string;
    };
  }): Promise<PaginatedResultSet<ReceiptsAndReceiptBatchesDto>>;

  getTotalBalancePeriod(dataIn: {
    params: GetTotalResidentBalanceQueryParams & {
      nursingHomeId: string;
    };
  }): Promise<TotalResidentBalanceResultDto>;

  getFilteredBalanceChangesPeriod(dataIn: {
    params: GetFilteredBalanceChangesQueryParams & {
      nursingHomeId: string;
    };
  }): Promise<FilteredTransactionChangesResultDto>;

  manualDeposit(deposit: ManualDepositDto): Promise<void>;

  cancelManualDeposit(dataIn: {
    transactionId: string;
    cashTransaction?: CancelManualDepositDto;
  }): Promise<void>;

  getReceiptBatchList(dataIn: {
    params: {
      nursingHomeId: string;
    };
  }): Promise<ReceiptBatchListDto[]>;

  getReceiptBatch(dataIn: {
    params: {
      batchId: string;
    };
  }): Promise<ReceiptBatchGetDto>;

  updateReceiptBatchCashTransactionGroup(dataIn: {
    body: UpdateReceiptBatchCashTransactionGroupDto;
    params: {
      batchId: string;
    };
  }): Promise<UpdateReceiptBatchResultDto>;
  // TODO: How to best port this

  deleteReceiptBatchEntry(dataIn: {
    params: {
      batchId: string;
      entryId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  createReceiptBatchJob(dataIn: {
    body: undefined | CreateReceiptBatchJobParams;
    params: {
      receiptBatchId: string;
    };
  }): Promise<{
    id: string;
  }>;
  // TODO: Implement phoenix version

  getReceiptBatchJob(dataIn: {
    params: {
      receiptBatchJobId: string;
    };
  }): Promise<ReceiptBatchJob>;
  // TODO: Implement phoenix version

  getResidentBalanceUntilDate(dataIn: {
    params: {
      residentId: string;
      familyMemberId?: string | null;
      date: Date;
    };
  }): Promise<{
    residentId: string;
    balance: number;
  }>;

  getResidentBalanceUntilDateAsFamilyMember(dataIn: {
    params: {
      residentId: string;
      familyMemberId?: string | null;
      date: Date;
    };
  }): Promise<{
    residentId: string;
    balance: number;
  }>;

  getReceiptBatchListPaginated(dataIn: {
    params: GetReceiptBatchesQueryDto & {
      nursingHomeId: string;
    };
  }): Promise<PaginatedResultSet<ReceiptBatchPaginatedDto>>;
}

const addTransactionTypesAsParams = (transactionTypes?: string[]): string => {
  return transactionTypes
    ? `${transactionTypes
        .map((type) => 'transactionSourceTypes[]=' + type)
        .join('&')}`
    : '';
};

function buildTransactionFilterQueryParams(
  opts:
    | GetTransactionsPaginatedQueryParams
    | GetFilteredBalanceChangesQueryParams
): URLSearchParams {
  const queryParams = new URLSearchParams();

  const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
  fields.forEach((field) => {
    const receiptDateField = opts.receiptDateRange?.[field];
    if (receiptDateField) {
      queryParams.append(
        `receiptDateRange[${field}]`,
        receiptDateField.toISOString()
      );
    }

    const submissionDateField = opts.submissionDateRange?.[field];
    if (submissionDateField) {
      queryParams.append(
        `submissionDateRange[${field}]`,
        submissionDateField.toISOString()
      );
    }
  });

  if (opts?.receiptTypeIds) {
    opts.receiptTypeIds.forEach((receiptTypeId, index) =>
      queryParams.append(`receiptTypeIds[${index}]`, receiptTypeId)
    );
  }

  if (opts?.serviceProviderIds) {
    opts.serviceProviderIds.forEach((serviceProviderId, index) =>
      queryParams.append(`serviceProviderIds[${index}]`, serviceProviderId)
    );
  }

  if (opts?.creatorIds) {
    opts.creatorIds.forEach((creatorId, index) =>
      queryParams.append(`creatorIds[${index}]`, creatorId)
    );
  }

  if (opts?.transactionSourceTypes) {
    opts.transactionSourceTypes.forEach((transactionSourceType, index) =>
      queryParams.append(
        `transactionSourceTypes[${index}]`,
        transactionSourceType
      )
    );
  }

  if (opts?.transactionTypes) {
    opts.transactionTypes.forEach((transactionType, index) =>
      queryParams.append(`transactionTypes[${index}]`, transactionType)
    );
  }

  if (opts?.initiators) {
    opts.initiators.forEach((initiator, index) =>
      queryParams.append(`initiators[${index}]`, initiator)
    );
  }

  if (opts?.search) {
    opts.search.fields.forEach((field, index) =>
      queryParams.append(`search[fields][${index}]`, field)
    );
    queryParams.append('search[value]', opts.search.value ?? '');
  }

  if (opts?.residentIds) {
    opts.residentIds.forEach((residentId, index) =>
      queryParams.append(`residentIds[${index}]`, residentId)
    );
  }

  if (opts?.familyMemberId !== undefined) {
    queryParams.append(
      'familyMemberId',
      opts.familyMemberId === null ? 'null' : opts.familyMemberId
    );
  }

  if (opts?.sortBy) {
    queryParams.append('sortBy', opts.sortBy);
  }

  if (opts?.sortOrder) {
    queryParams.append('sortOrder', opts.sortOrder);
  }

  return queryParams;
}

export class TransactionApi implements ITransactionApi {
  baseUrl: string;

  public get: ITransactionApi['get'];
  public getPaginated: ITransactionApi['getPaginated'];
  public getTransactionsPaginated: ITransactionApi['getTransactionsPaginated'];
  public getTotalBalancePeriod: ITransactionApi['getTotalBalancePeriod'];
  public getFilteredBalanceChangesPeriod: ITransactionApi['getFilteredBalanceChangesPeriod'];

  public getReceiptBatch: ITransactionApi['getReceiptBatch'];

  public getReceiptBatchList: ITransactionApi['getReceiptBatchList'];
  // TODO

  // None phoenix version exists

  public updateReceiptBatchCashTransactionGroup: ITransactionApi['updateReceiptBatchCashTransactionGroup'];
  // TODO

  public deleteReceiptBatchEntry: ITransactionApi['deleteReceiptBatchEntry'];

  // No legacty version exists

  public createReceiptBatchJob: ITransactionApi['createReceiptBatchJob'];
  // TODO
  public getReceiptBatchJob: ITransactionApi['getReceiptBatchJob'];

  public getResidentBalanceUntilDate: ITransactionApi['getResidentBalanceUntilDate'];
  public getResidentBalanceUntilDateAsFamilyMember: ITransactionApi['getResidentBalanceUntilDateAsFamilyMember'];

  public getReceiptBatchListPaginated: ITransactionApi['getReceiptBatchListPaginated'];
  // TODO
  public getReceiptsAndReceiptBatchesPaginated: ITransactionApi['getReceiptsAndReceiptBatchesPaginated'];
  // TODO

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getApiBaseUrl(tenantId, apiUrl);
    this.baseUrl = baseUrl;

    this.getReceiptBatchList = get({
      authContext,
      url: ({ nursingHomeId }) =>
        `${baseUrl}/${endpoints.receiptBatches}/${endpoints.receiptBatchesGet}?nursingHomeId=${nursingHomeId}`,
    });

    this.getReceiptBatch = get({
      authContext,
      url: ({ batchId }) =>
        `${baseUrl}/${endpoints.receiptBatches}/${endpoints.receiptBatchGetById}`.replace(
          ':batchId',
          batchId
        ),
    });

    this.updateReceiptBatchCashTransactionGroup = modify<
      UpdateReceiptBatchCashTransactionGroupDto,
      UpdateReceiptBatchResultDto
    >({
      authContext,
      method: 'PUT',
      url: ({ batchId }) =>
        `${baseUrl}/${endpoints.receiptBatches}/${endpoints.receiptBatchCashTransactionPut}`.replace(
          ':batchId',
          batchId
        ),
    });

    this.deleteReceiptBatchEntry = modify<
      undefined,
      {
        success: boolean;
      }
    >({
      authContext,
      method: 'DELETE',
      url: ({ batchId, entryId }) =>
        `${this.baseUrl}/${
          endpoints.receiptBatches
        }/${endpoints.receiptBatchEntriesDelete
          .replace(':batchId', batchId)
          .replace(':entryId', entryId)}`,
    });

    this.createReceiptBatchJob = modify<
      undefined,
      {
        id: string;
      },
      {
        id: string;
      },
      {
        receiptBatchId: string;
      }
    >({
      authContext,
      url: ({ receiptBatchId }) =>
        `${baseUrl}/${endpoints.receiptBatches}/${endpoints.receiptBatchesJobPost}`.replace(
          ':batchId',
          receiptBatchId
        ),
    });
    // TODO

    this.getReceiptBatchJob = get({
      authContext,
      url: ({ receiptBatchJobId }) =>
        `${baseUrl}/${endpoints.receiptBatches}/${endpoints.receiptBatchesJobGet}`.replace(
          ':jobId',
          receiptBatchJobId
        ),
    });
    // TODO

    this.getResidentBalanceUntilDate = get({
      authContext,
      url: ({ residentId, familyMemberId, date }) => {
        const queryParams = new URLSearchParams();

        queryParams.append('date', date.toISOString());

        if (familyMemberId !== undefined) {
          queryParams.append(
            'family_member_id',
            familyMemberId === null ? 'null' : familyMemberId
          );
        }

        return `${baseUrl}/${endpoints.transactions}/${
          endpoints.transactionsGetResidentBalance
        }?${queryParams.toString()}`.replace(':resident_id', residentId);
      },
    });

    this.getResidentBalanceUntilDateAsFamilyMember = get({
      authContext,
      url: ({ residentId, familyMemberId, date }) => {
        const queryParams = new URLSearchParams();

        queryParams.append('date', date.toISOString());

        if (familyMemberId !== undefined) {
          queryParams.append(
            'family_member_id',
            familyMemberId === null ? 'null' : familyMemberId
          );
        }

        return `${baseUrl}/${endpoints.familyMemberTransactions}/${
          endpoints.familyMemberTransactionsGetResidentBalance
        }?${queryParams.toString()}`.replace(':resident_id', residentId);
      },
    });

    this.get = get({
      authContext,
      url: ({ nursingHomeId }) =>
        `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsGetAll}?nursingHomeId=${nursingHomeId}`,
      transform: (data) => {
        return data?.map((d: any) => ({
          ...d,
          date: new Date(d.date),
          submissionDate: d.submissionDate
            ? new Date(d.submissionDate)
            : undefined,
          created_on: new Date(d.created_on),
        }));
      },
    });

    this.getPaginated = get({
      authContext,

      url: ({
        nursingHomeId,
        page,
        pageSize,
        cashTransactionGroupId,
        status,
        additionalFields,
        serviceProviderIds,
        submissionDateRange,
        receiptTypeIds,
        receiptDateRange,
        sortOrder,
        residentIds,
        sortBy,
      }) => {
        const queryParams = new URLSearchParams();
        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const receiptDateField = receiptDateRange?.[field];
          if (receiptDateField) {
            queryParams.append(
              `receiptDateRange[${field}]`,
              receiptDateField.toDateString()
            );
          }

          const submissionDateField = submissionDateRange?.[field];
          if (submissionDateField) {
            queryParams.append(
              `submissionDateRange[${field}]`,
              submissionDateField.toISOString()
            );
          }
        });

        if (receiptTypeIds) {
          receiptTypeIds.forEach((receiptTypeId, index) =>
            queryParams.append(`receiptTypeIds[${index}]`, receiptTypeId)
          );
        }

        if (serviceProviderIds) {
          serviceProviderIds.forEach((serviceProviderId, index) =>
            queryParams.append(
              `serviceProviderIds[${index}]`,
              serviceProviderId
            )
          );
        }

        if (residentIds) {
          residentIds.forEach((residentId, index) =>
            queryParams.append(`residentIds[${index}]`, residentId)
          );
        }

        if (status) {
          status.forEach((s, index) =>
            queryParams.append(`status[${index}]`, s)
          );
        }

        if (additionalFields) {
          additionalFields.forEach((field, index) =>
            queryParams.append(`additionalFields[${index}]`, field)
          );
        }

        queryParams.append('nursingHomeId', nursingHomeId);
        queryParams.append('page', page.toString());
        queryParams.append('pageSize', pageSize.toString());

        if (sortBy) {
          queryParams.append('sortBy', sortBy);
        }

        if (sortOrder) {
          queryParams.append('sortOrder', sortOrder);
        }

        if (cashTransactionGroupId) {
          queryParams.append('cashTransactionGroupId', cashTransactionGroupId);
        }

        const queryString = queryParams.toString();

        return `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsGetPaginated}?${queryString}`;
      },
      transform: (response) => {
        return {
          ...response,
          data: response.data.map((d: any) => ({
            ...d,
            date: new Date(d.date),
            submissionDate: d.submissionDate
              ? new Date(d.submissionDate)
              : undefined,
            created_on: new Date(d.created_on),
          })),
        };
      },
    });

    this.getTransactionsPaginated = get({
      authContext,
      url: (opts) => {
        const queryParams = buildTransactionFilterQueryParams(opts);

        queryParams.append('page', opts.page.toString());
        queryParams.append('pageSize', opts.pageSize.toString());

        return `${this.baseUrl}/${
          endpoints.transactions
        }/${endpoints.transactionsGetPaginatedOfNursingHome.replace(
          ':nursing_home_id',
          opts.nursingHomeId
        )}?${queryParams.toString()}`;
      },
    });

    this.getTotalBalancePeriod = get({
      authContext,
      url: (opts) => {
        const queryParams = new URLSearchParams();

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const submissionDateField = opts.submissionDateRange?.[field];
          if (submissionDateField) {
            queryParams.append(
              `submissionDateRange[${field}]`,
              submissionDateField.toISOString()
            );
          }
        });

        if (opts?.residentIds) {
          opts.residentIds.forEach((residentId, index) =>
            queryParams.append(`residentIds[${index}]`, residentId)
          );
        }

        return `${this.baseUrl}/${
          endpoints.transactions
        }/${endpoints.transactionsGetTotalBalancePeriod.replace(
          ':nursing_home_id',
          opts.nursingHomeId
        )}?${queryParams.toString()}`;
      },
    });

    this.getFilteredBalanceChangesPeriod = get({
      authContext,
      url: (opts) => {
        const queryParams = buildTransactionFilterQueryParams(opts);

        return `${this.baseUrl}/${
          endpoints.transactions
        }/${endpoints.transactionsGetFilteredBalanceChangesPeriod.replace(
          ':nursing_home_id',
          opts.nursingHomeId
        )}?${queryParams.toString()}`;
      },
    });

    this.getReceiptBatchListPaginated = get({
      authContext,

      url: (opts) => {
        const queryParams = new URLSearchParams();
        queryParams.append('page', opts.page.toString());
        queryParams.append('pageSize', opts.pageSize.toString());

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const amountField = opts.amount?.[field];
          if (amountField) {
            queryParams.append(`amount[${field}]`, amountField.toString());
          }

          const receiptCountField = opts.receiptCount?.[field];
          if (receiptCountField) {
            queryParams.append(
              `receiptCount[${field}]`,
              receiptCountField.toString()
            );
          }

          const receiptDateField = opts.receiptDateRange?.[field];
          if (receiptDateField) {
            queryParams.append(
              `receiptDateRange[${field}]`,
              receiptDateField.toISOString()
            );
          }

          const submissionDateField = opts.submissionDateRange?.[field];
          if (submissionDateField) {
            queryParams.append(
              `submissionDateRange[${field}]`,
              submissionDateField.toISOString()
            );
          }
        });

        if (opts?.receiptTypeIds) {
          opts.receiptTypeIds.forEach((receiptTypeId, index) =>
            queryParams.append(`receiptTypeIds[${index}]`, receiptTypeId)
          );
        }

        if (opts?.serviceProviderIds) {
          opts.serviceProviderIds.forEach((serviceProviderId, index) =>
            queryParams.append(
              `serviceProviderIds[${index}]`,
              serviceProviderId
            )
          );
        }

        if (opts?.state === null) {
          queryParams.append('state', '');
        } else if (opts?.state) {
          opts.state.forEach((state, index) =>
            queryParams.append(`state[${index}]`, state)
          );
        }

        if (opts?.serviceProviderNameSearch) {
          queryParams.append(
            'serviceProviderNameSearch',
            opts.serviceProviderNameSearch
          );
        }

        if (opts?.residentId) {
          queryParams.append('residentId', opts.residentId);
        }

        if (opts?.recurringItemId) {
          queryParams.append('recurringItemId', opts.recurringItemId);
        }

        if (opts?.sort) {
          queryParams.append('sort', opts.sort);
        }

        if (opts?.sortOrder) {
          queryParams.append('sortOrder', opts.sortOrder);
        }

        const queryString = queryParams.toString();

        const paginated = generatePath(endpoints.receiptBatchesGetPaginated, {
          nursingHomeId: opts.nursingHomeId,
        });

        return `${this.baseUrl}/${endpoints.receiptBatches}/${paginated}?${queryString}`;
      },
    });

    this.getReceiptsAndReceiptBatchesPaginated = get({
      authContext,
      url: (opts) => {
        const queryParams = new URLSearchParams();

        queryParams.append('page', opts.page.toString());
        queryParams.append('pageSize', opts.pageSize.toString());

        if (opts.state) {
          queryParams.append('state', opts.state);
        }

        const fields = ['lt', 'gt', 'eq', 'gte', 'lte'] as const;
        fields.forEach((field) => {
          const dateField = opts.submissionDate?.[field];
          if (dateField) {
            queryParams.append(
              `submissionDate[${field}]`,
              dateField.toISOString()
            );
          }
        });

        if (opts?.sort) {
          queryParams.append('sort', opts.sort);
        }

        if (opts?.sortOrder) {
          queryParams.append('sortOrder', opts.sortOrder);
        }

        const path = generatePath(
          endpoints.transactionsGetReceiptsAndReceiptBatches,
          {
            nursing_home_id: opts.nursingHomeId,
          }
        );

        return `${this.baseUrl}/${
          endpoints.transactions
        }/${path}?${queryParams.toString()}`;
      },
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get headers(): Headers {
    return new Headers({
      'content-type': 'application/json',
    });
  }

  async create(data: CreateReceiptDto): Promise<ReceiptFull> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsPostReceipt}`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify(data),
      }
    );
    return result.json();
  }

  async update(data: UpdateTransactionDto): Promise<ReceiptFull> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsPutReceipt}`,
      {
        method: 'PUT',
        headers: this.headers,
        body: JSON.stringify(data),
      }
    );
    return result.json();
  }

  async cancel({ receiptId, note }: CancelReceiptDto): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.transactions
      }/${endpoints.transactionsPostCancelReceipt.replace(
        ':receiptId',
        receiptId
      )}`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify({
          note,
        }),
      }
    );
  }

  async getOne(
    transactionId: string,
    query?: {
      optionalFields?: {
        linkedTransactionGroups?: boolean;
        receiptBatch?: boolean;
      };
    }
  ): Promise<ReceiptFull> {
    const queryParameters = new URLSearchParams();
    if (query?.optionalFields?.linkedTransactionGroups) {
      queryParameters.append('optionalFields[linkedTransactionGroups]', 'true');
    }
    if (query?.optionalFields?.receiptBatch) {
      queryParameters.append('optionalFields[receiptBatch]', 'true');
    }
    const queryString = queryParameters.toString();

    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.transactions
      }/${endpoints.transactionsGet.replace(
        ':id',
        transactionId
      )}?${queryString}`,
      {
        headers: this.headers,
      }
    );
    const data = await result.json();
    return data;
  }

  async getTypes(): Promise<IReceiptType[]> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsGetReceiptTypes}`,
      {
        headers: this.headers,
      }
    );
    return result.json();
  }

  async delete(transactionId: string): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.transactions
      }/${endpoints.transactionsDelete.replace(':id', transactionId)}`,
      {
        method: 'DELETE',
        headers: this.headers,
      }
    );
    return;
  }

  async submit(receiptIds: string[]): Promise<
    Array<{
      id: string;
    }>
  > {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsPostSubmit}`,
      {
        method: 'POST',
        headers: this.headers,
        body: JSON.stringify({ receiptIds }),
      }
    );
    return result.json();
  }

  async getUploadUrl(
    receiptId: string,
    fileType: string,
    md5Hash: string
  ): Promise<string | undefined> {
    const result = await superFetch(
      this.authContext,
      `${
        this.baseUrl
      }/receipt-image/${receiptId}?fileType=${fileType}&md5Hash=${encodeURIComponent(
        md5Hash
      )}`,
      {
        method: 'POST',
        headers: this.headers,
      }
    );
    return result.text();
  }

  async getReceiptImageUrls(receiptIds: string[]): Promise<
    | {
        [key: string]: string | undefined;
      }
    | undefined
  > {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/receipt-image/multi`,
      {
        body: JSON.stringify({ receiptIds }),
        method: 'POST',
        headers: this.headers,
      }
    );
    if (result.ok) {
      return await result.json();
    }
    return undefined;
  }

  async getTransactionsAsFamilyMember(
    residentId: string,
    transactionTypes?: string[],
    familyMemberId?: string | null
  ): Promise<GetTransactionsWithReceiptDto[]> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.familyMemberTransactions
      }/${endpoints.familyMemberTransactionsGetByResident.replace(
        ':resident_id',
        residentId
      )}?${addTransactionTypesAsParams(transactionTypes)}${
        familyMemberId !== undefined
          ? `&familyMemberId=${
              familyMemberId === null ? 'null' : familyMemberId
            }`
          : ''
      }`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );

    return await result.json();
  }

  async getTransactions(
    residentId: string,
    transactionTypes?: string[]
  ): Promise<GetTransactionsWithReceiptDto[]> {
    const result = await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.transactions
      }/${endpoints.transactionsGetByResident.replace(
        ':resident_id',
        residentId
      )}?${addTransactionTypesAsParams(transactionTypes)}`,
      {
        method: 'GET',
        headers: this.headers,
      }
    );

    return await result.json();
  }

  async manualDeposit(deposit: ManualDepositDto): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${endpoints.transactions}/${endpoints.transactionsPostManualDeposit}`,
      {
        method: 'POST',
        body: JSON.stringify(deposit),
        headers: this.headers,
      }
    );

    return;
  }

  async cancelManualDeposit({
    transactionId,
    cashTransaction,
  }: {
    transactionId: string;
    cashTransaction?: CancelManualDepositDto;
  }): Promise<void> {
    await superFetch(
      this.authContext,
      `${this.baseUrl}/${
        endpoints.transactions
      }/${endpoints.transactionsPostCancelManualDeposit.replace(
        ':transactionId',
        transactionId
      )}`,
      {
        method: 'POST',
        body: cashTransaction ? JSON.stringify(cashTransaction) : undefined,
        headers: this.headers,
      }
    );

    return;
  }
}
