// Keycloak Authentication URL
export const auth = 'realms/:realm/protocol/openid-connect/token';

// Healthcheck
export const healthcheck = 'healthcheck';
export const healthcheckGet = '';
export const healthcheckGetWithKeycloak = 'keycloak';

// User Profile
export const userProfile = 'userProfile';
export const userProfileGet = '';
export const userProfileEmployee = 'employeeProfile';
export const userProfileEmployeeGet = '';

export const userProfilePostTestThrow400 = 'test-throw-400';

export const userProfileEmployeeGetAllOfNursingHome =
  'nursingHome/:nursingHomeId';

// Residents
export const residents = 'residents';
/** @deprecated Use residentsGetAllPaginated instead; WARNING: New endpoint returns amounts in cents! */
export const residentsGetAll = '';
export const residentsGetAllPaginated = 'paginated';
export const residentsGet = ':resident_id';
export const residentsPutResident = '';
export const residentsGetConfiguration = 'configuration/:resident_id';
export const residentsPostConfiguration = 'configuration';

// Residents Settlement
export const residentsSettlement = 'residents'; // same as for other resident endpoints
export const residentsSettlementPost = ':resident_id/settlement';
export const residentsSettlementGet = ':resident_id/settlement';

// Resident Service Providers
export const residentsServiceProviders = ':resident_id/service-providers';

export const residentCashPayerStatus = ':resident_id/cash-payer';

export const residentsSettlementPutReceiptsFrozen =
  ':resident_id/settlement/receiptsFrozen';
export const residentsSettlementPutReceiptsUnfrozen =
  ':resident_id/settlement/receiptsUnfrozen';
export const residentsSettlementPostResidentArchived =
  ':resident_id/settlement/residentArchived';

// Transactions
export const transactions = 'transactions';
/** @deprecated Use transactionsGetPaginated instead */
export const transactionsGetAll = '';
export const transactionsGetPaginated = 'paginated';
export const transactionsPostSubmit = 'submit';
export const transactionsPostSubmitAll = 'submitAll';
export const transactionsGetReceiptTypes = 'types';
export const transactionsPostCancelReceipt = 'cancel/:receiptId';
/** @deprecated Use transactionsGetServiceProvidersPaginated instead */
export const transactionsGetServiceProviders = 'serviceProviders';
export const transactionsGetServiceProvidersPaginated =
  'serviceProvidersPaginated';
export const transactionsPutReceipt = '';
export const transactionsPostReceipt = '';
export const transactionsGet = ':id';
export const transactionsDelete = ':id';
export const transactionsPostManualDeposit = 'manual-deposit';
export const transactionsGetByResident = 'resident/:resident_id';
export const transactionsGetResidentBalance = 'resident/:resident_id/balance';
export const transactionsGetPaginatedOfNursingHome =
  'nursing-home/:nursing_home_id/paginated';
export const transactionsGetTotalBalancePeriod =
  'nursing-home/:nursing_home_id/total-balance';
export const transactionsGetFilteredBalanceChangesPeriod =
  'nursing-home/:nursing_home_id/balance-change';
export const transactionsPostCancelManualDeposit =
  'manualDeposits/:transactionId/cancel';
export const transactionsGetReceiptsAndReceiptBatches =
  'nursing-home/:nursing_home_id/receipts-and-receipt-batches';

// Service providers
export const serviceProviders = 'serviceProviders';
export const serviceProvidersPost = '';
export const serviceProvidersPatch = ':id';

// Family member transactions
export const familyMemberTransactions = 'transactions/family-member';
export const familyMemberTransactionsGetResidentBalance =
  'resident/:resident_id/balance';
export const familyMemberTransactionsGetByResident = 'resident/:resident_id';

// Cash Management
export const cashListConfiguration = 'cash-list-configuration';
export const cashListConfigurationGetCashListConfiguration = ':nursingHomeId';

export const cashList = 'cash-list';
export const cashListPostTransfer = ':fromCashListId/transfer/:toCashListId';

export const cashManagement = 'cash-management';
export const cashManagementGet = '';
export const cashManagementGetTransactionId = 'transactions/:id';
export const cashManagementGetTransactionByTransactionId =
  'transactions/by-transaction-id/:transactionId';
export const cashManagementGetTransactionsForPeriod = 'transactions-for-period';
export const cashManagementPostTransactions = 'transactions';
export const cashManagementGetTransactionGroups = 'transactionGroups';
export const cashManagementGetTransactionGroup = 'transactionGroups/:groupId';
export const cashManagementGetTransactionGroupLinkHistory = 'linkHistory';
export const cashManagementPostTransactionGroupTransaction =
  'transactionGroups/:groupId/transaction';

export const cashManagementPostTransactionGroupLink =
  'transactionGroups/:groupId/link';
export const cashManagementDeleteTransactionGroupLink =
  'transactionGroups/:groupId/link/:link';
export const cashManagementPostTransactionGroups = 'transactionGroups';
export const cashManagementTransactionGroupMismatch =
  'transactionGroups/:groupId/mismatch';

// Images
export const images = 'images';
export const imagesGet = ':imageId';

// Nursing Home
export const nursingHomes = 'nursing-homes';
export const nursingHomesGetById = ':nursingHomeId';
export const nursingHomesPut = '';

export const nursingHomesPatchPaymentSettings =
  ':nursingHomeId/paymentSettings';

// Recurring Items
export const recurringItems = 'recurringItems';
export const recurringItemsGetAll = '';
export const recurringItemsGetByResident = 'resident/:residentId';
export const recurringItemsPutResidentStartDate =
  ':recurringItemId/residents/:residentId';
export const recurringItemsGetResidents = ':recurringItemId/residents';
export const recurringItemsDelete = ':recurringItemId';
export const recurringItemsPostResident = ':recurringItemId/residents';
export const recurringItemsDeleteResident =
  ':recurringItemId/residents/:residentId';

// ReceiptBatches
export const receiptBatches = 'receipt-batches';
export const receiptBatchesPost = '';
export const receiptBatchCashTransactionPut = ':batchId/cashTransactionGroup';
export const receiptBatchesPut = ':batchId';
export const receiptBatchesPatch = ':batchId';
export const receiptBatchEntriesPatch = ':batchId/receipt/:receiptId';
export const receiptBatchEntriesDelete = ':batchId/receipt/:entryId';
export const receiptBatchDelete = ':batchId';
export const receiptBatchGetById = ':batchId';
export const receiptBatchesGet = '';
export const receiptBatchesGetPaginated = 'nursingHome/:nursingHomeId';
export const receiptBatchEntriesPost = ':batchId/receipt';
export const receiptBatchesJobPost = ':batchId/job';
export const receiptBatchesJobGet = 'jobs/:jobId';

// Family Member Tenant
export const familyMemberTenant = 'familyMemberTenant';
export const familyMemberTenantGet = '';

// Payment transfers
export const paymentTransfer = 'payment/transfer';
export const paymentTransferGet = '';

// Payment dashboard
export const paymentDashboard = 'payment/dashboard';
export const paymentDashboardGet = '';
export const paymentDashboardGetResidentsBalanceGraph = 'residentsBalanceGraph';

// Payment payouts
export const paymentPayouts = 'payment/payouts';
export const paymentPayoutsGet = '';
export const paymentPayoutsGetReconciliation = ':payoutId/reconciliation';

export const nursingHomeDirectDebitParamName = 'nursingHomeId';
export const setupIntentIdParamName = 'intentId';

// Payment payments
export const paymentPaymentsResidentIdParamName = 'residentId';
export const paymentPaymentsFamilyMemberIdParamName = 'familyMemberId';
export const paymentPayments = 'payment';
export const paymentPaymentsGet = 'payments';
export const paymentPaymentsSetPaymentInitiation = `:${paymentPaymentsResidentIdParamName}/payment-initiation/:${paymentPaymentsFamilyMemberIdParamName}`;
export const paymentPendingReversalsGet = 'reversals/pending';
export const paymentPaymentsGetResidentPaymentInfo = `:${paymentPaymentsResidentIdParamName}/payment-info`;
export const paymentPaymentsGetStripeAccountByNursingHome =
  'stripe/account/:nursingHomeId';
export const paymentPaymentsCreateStripeAccountForNursingHome = `stripe/account/:nursingHomeId`;
export const paymentPaymentsGetStripeAccountStatusByNursingHome =
  'stripe/account/:nursingHomeId/status';
export const paymentMoneyFlowGet = 'money-flow';
export const nursingHomeDirectDebitSetup = `:${nursingHomeDirectDebitParamName}/stripe/setup`;
export const nursingHomeDirectDebitSetupConfirm = `:${nursingHomeDirectDebitParamName}/:${setupIntentIdParamName}/direct-debit/confirm`;
export const getNursingHomeDirectDebitStatus = `:${nursingHomeDirectDebitParamName}/direct-debit-status`;
export const deleteNursingHomeDirectDebitMandate = `:${nursingHomeDirectDebitParamName}/direct-debit-mandate`;

/** @deprecated */
export const paymentPaymentsGetStripeAccount = 'stripe/account';

/** @deprecated */
export const employeePaymentsGetPaymentInitiatedBy =
  'paymentInitiatedBy/:residentId';

// Family member payments
export const familyMemberPaymentsResidentIdParamName = 'residentId';
export const familyMemberPayments = 'payment/family-member';
/** @deprecated */
export const familyMemberPaymentsGetPaymentInitiatedBy = `paymentInitiatedBy/:${familyMemberPaymentsResidentIdParamName}`;
export const familyMemberPaymentsStripeSetup = `:${familyMemberPaymentsResidentIdParamName}/stripe/setup`;
export const familyMemberPaymentsDirectDebitConfirm = `:${familyMemberPaymentsResidentIdParamName}/direct-debit/confirm`;
export const familyMemberPaymentsDirectDebitPayment = `:${familyMemberPaymentsResidentIdParamName}/direct-debit/payment`;
export const familyMemberPaymentsDirectDebitPaymentConfirm = `:${familyMemberPaymentsResidentIdParamName}/direct-debit/payment/confirm`;
export const familyMemberPaymentsDirectDebitStatus = `:${familyMemberPaymentsResidentIdParamName}/direct-debit`;
export const familyMemberPaymentsDirectDebitDelete = `:${familyMemberPaymentsResidentIdParamName}/direct-debit`;
export const familyMemberPaymentsGetPaymentInfo = `:${familyMemberPaymentsResidentIdParamName}/payment-info`;
export const familyMemberPaymentsGetBalanceInfo = `:${familyMemberPaymentsResidentIdParamName}/balance-info`;

// Service Provider Payments
export const serviceProviderPayments = 'payment/service-provider';
export const serviceProviderPaymentsGet = '';
export const serviceProviderPaymentsPostForReceiptBatch = ':receiptBatchId';
export const serviceProviderPaymentsGetByReceiptBatch = ':receiptBatchId';
export const serviceProviderPaymentsGetPaymentStatusByReceiptBatch =
  ':receiptBatchId/payment-status';

// Analytics
export const analytics = 'analytics';
export const analyticsGet = '';
export const analyticsGetAllResidentBalance = 'all-resident-balance';
export const analyticsGetBalanceReport = 'balance-report';
export const analyticsGetBalanceSummaryReport = 'balance-summary-report';
export const analyticsGetResidentSummary = ':residentId/summary';
export const analyticsGetByResident = ':residentId';

// Service Provider
export const serviceProvider = 'nursing-home/:nursingHomeId/service-provider';
export const serviceProviderCreate = '';
export const serviceProviderUpdateFields = ':serviceProviderId';
/** @deprecated */
export const serviceProviderGet = ':serviceProviderId';
export const serviceProviderStatus = ':serviceProviderId/status';
export const serviceProviderInviteStatus = ':serviceProviderId/inviteStatus';
export const serviceProviderGetResidents = ':serviceProviderId/residents';
export const serviceProviderPutResidents = ':serviceProviderId/residents';

// Superadmin
export const superadminIbanQueryParamName = 'iban';
export const superadminAppQueryParamName = 'app';
export const superadminPlatformQueryParamName = 'platform';
export const superadminTTLQueryParamName = 'ttl';
export const superadmin = 'superadmin';
export const superadminClearCache = 'clearCache';
export const superadminTriggerAllSystems = 'triggerAllSystems';
export const superadminTriggerPayments = 'triggerPayments';
export const superadminTriggerPayouts = 'triggerPayouts';
export const superadminTriggerPendingCharges = 'triggerPendingCharges';
export const superadminMoveAllUpcomingToPending = 'moveAllUpcomingToPending';
export const superadminGetStripeEvents = 'stripe/events';
export const superadminGetAllPendingCharges = 'all-pending-charges';
export const superadminTriggerManualPayments = 'triggerManualPayments';
export const superadminPostPayouts = 'mock-payouts';
export const superadminDeletePayouts = 'mock-payouts';
export const superadminTriggerPendingReversals = 'triggerPendingReversals';
export const superadminTriggerTransferReversals = 'triggerTransferReversals';

export const superadminSetPaymentsDisabledStateForAllNursingHomes =
  'paymentsDisabled';
export const superadminRemoveAllPendingReversals = 'removeAllPendingReversals';
export const superadminAddMoneyToStripeImmediately =
  'addMoneyToStripeImmediately';
export const superadminSetVersionEOL = 'setVersionEOL';
export const superadminImportCsv = 'import-csv';
export const superadminSendBackendEvent = 'backend-event';

export const superadminAddTenant = 'addTenant';
export const superadminRemoveTenant = 'removeTenant';

export const superadminRemoveRepeatedTasks = 'removeRepeatedTasks';

export const superadminAddRepeatedTasks = 'addRepeatedTasks';

export const paymentResidentBalanceGraphGet = 'resident-balance-graph';

// Versioning

export const versionFamilyMemberApp = 'version/app/family-member';
export const versionNursingHomeApp = 'version/app/nursing-home';

// Employees
export const employees = 'employees';
export const employeesGet = '';
export const employeesGetById = ':employeeId';
export const employeesPost = '';
export const employeesPatch = ':employeeId';
export const employeesSetState = ':employeeId/set-state';

// Countries and tax codes
export const countries = 'countries';
export const taxCodesByCountryId = '/countries/:countryId/tax-codes';
