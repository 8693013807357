import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext } from 'react';
import type { GetEmployeesPhoenixResponse, IEmployeesPhoenixApi } from './api';
import { EmployeesPhoenixApi } from './api';
import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type {
  AddEmployee,
  Employee,
  UpdateEmployee,
} from '@pflegenavi/shared/api';

const ApiContext = createContext<IEmployeesPhoenixApi | undefined>(undefined);
const {
  useApi: useEmployeesPhoenixApi,
  ApiProvider: EmployeesPhoenixApiProvider,
} = makeApiProvider({
  name: 'EmployeesPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new EmployeesPhoenixApi(tenantId, auth, apiUrl),
});

export { useEmployeesPhoenixApi, EmployeesPhoenixApiProvider };
export type { IEmployeesPhoenixApi } from './api';

const GET_ALL_EMPLOYEES_MAIN_PHOENIX_KEY: QueryKey = [
  'GET_ALL_EMPLOYEES_MAIN_PHOENIX',
];
const GET_ALL_EMPLOYEES_PHOENIX_KEY = (nursingHomeId?: string) => [
  GET_ALL_EMPLOYEES_MAIN_PHOENIX_KEY,
  nursingHomeId,
];
const ADD_EMPLOYEE_KEY: QueryKey = ['ADD_EMPLOYEE_KEY'];
const UPDATE_EMPLOYEE_KEY: QueryKey = ['UPDATE_EMPLOYEE_KEY'];
const RESEND_WELCOME_EMAIL_KEY: QueryKey = ['RESEND_WELCOME_EMAIL_KEY'];

export const useGetNursingHomeEmployees = ({
  nursingHomeId,
  options,
}: {
  nursingHomeId?: string;
  options?: Omit<
    UseQueryOptions<GetEmployeesPhoenixResponse, unknown>,
    'queryFn' | 'queryKey'
  >;
}): UseQueryResult<GetEmployeesPhoenixResponse, unknown> => {
  return useApiQuery(
    useEmployeesPhoenixApi,
    GET_ALL_EMPLOYEES_PHOENIX_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getNursingHomeEmployees({
        params: {
          nursingHomeId,
        },
      });
    },
    {
      ...options,
      enabled: !!nursingHomeId,
    }
  );
};

export const useAddEmployee = ({
  nursingHomeId,
}: {
  nursingHomeId: string | undefined;
}): UseMutationResult<
  {
    data: Employee;
  },
  unknown,
  AddEmployee
> => {
  const api = useEmployeesPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: Employee;
    },
    unknown,
    AddEmployee
  >({
    mutationKey: ADD_EMPLOYEE_KEY,
    mutationFn: (data) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.addEmployee({
        params: {
          nursingHomeId,
        },
        body: data,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: GET_ALL_EMPLOYEES_PHOENIX_KEY(nursingHomeId),
      });
    },
  });
  return result;
};

export const useUpdateEmployee = ({
  employeeId,
  nursingHomeId,
}: {
  employeeId: string;
  nursingHomeId: string | undefined;
}): UseMutationResult<
  {
    data: Employee;
  },
  unknown,
  UpdateEmployee
> => {
  const api = useEmployeesPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: Employee;
    },
    unknown,
    UpdateEmployee
  >({
    mutationKey: UPDATE_EMPLOYEE_KEY,
    mutationFn: (data) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.updateEmployee({
        params: {
          employeeId,
          nursingHomeId,
        },
        body: data,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: GET_ALL_EMPLOYEES_PHOENIX_KEY(nursingHomeId),
      });
    },
  });
  return result;
};

export const useResendEmployeeWelcomeEmail = ({
  employeeId,
  nursingHomeId,
}: {
  employeeId: string;
  nursingHomeId: string | undefined;
}): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  void
> => {
  const api = useEmployeesPhoenixApi();
  const result = useMutation<
    {
      success: boolean;
    },
    unknown,
    void
  >({
    mutationKey: RESEND_WELCOME_EMAIL_KEY,
    mutationFn: () => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.resentWelcomeEmail({
        params: {
          employeeId,
          nursingHomeId,
        },
      });
    },
  });
  return result;
};
