import type { ApiProviderProps } from '@pflegenavi/shared-frontend/platform';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type {
  AllResidentBalanceDto,
  ReportingDto,
  SummaryReportDto,
} from '@pflegenavi/shared/api';
import type { RangeDateFilter } from '@pflegenavi/shared/utils';
import type { FC } from 'react';
import { createContext } from 'react';
import { type UseQueryResult } from '@tanstack/react-query';
import { ReportingApi } from './api';
import {
  ALL_RESIDENT_BALANCE_KEY,
  REPORTING_KEY,
  SUMMARY_REPORT_KEY,
} from './queryKeys';

const ApiContext = createContext<ReportingApi | undefined>(undefined);
const { useApi: useReportingApi, ApiProvider } = makeApiProvider({
  name: 'Reporting',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => new ReportingApi(tenantId, auth, apiUrl),
});

const ReportingApiProvider: FC<ApiProviderProps> = ({ children, ...props }) => {
  return <ApiProvider {...props}>{children}</ApiProvider>;
};

export { ReportingApiProvider, useReportingApi };

export const useAllResidentBalance = (
  nursingHomeId?: string
): UseQueryResult<AllResidentBalanceDto, unknown> => {
  return useApiQuery(
    useReportingApi,
    ALL_RESIDENT_BALANCE_KEY(nursingHomeId),
    async (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return await api.getAllResidentBalance({
        params: { nursingHomeId },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useBalanceReport = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): UseQueryResult<ReportingDto, unknown> => {
  return useApiQuery(
    useReportingApi,
    REPORTING_KEY(nursingHomeId, dateRange),
    async (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return await api.getBalanceReport({
        params: {
          dateRange: dateRange,
          nursingHomeId,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useBalanceSummaryReport = (
  nursingHomeId: string | undefined,
  dateRange: RangeDateFilter<Date>
): UseQueryResult<SummaryReportDto, unknown> => {
  return useApiQuery(
    useReportingApi,
    SUMMARY_REPORT_KEY(nursingHomeId, dateRange),
    async (api) => {
      if (!nursingHomeId) {
        throw new Error('No nursing home selected');
      }
      return await api.getBalanceSummaryReport({
        params: {
          dateRange: dateRange,
          nursingHomeId,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};
