import type { QueryClient, QueryKey } from '@tanstack/react-query';

export const CASH_LIST_CONFIGURATION_KEY = (
  nursingHomeId: string | undefined
): QueryKey => ['cash-list-configuration', nursingHomeId];

export const CASH_LIST_TRANSACTIONS_KEY: QueryKey = ['cash-list-transactions'];
export const CASH_TRANSACTIONS_KEY = (
  by: 'id' | 'transactionId',
  id: string | undefined
): QueryKey => [
  ...CASH_LIST_TRANSACTIONS_KEY,
  {
    by,
    id,
  },
];

export const CASH_LIST_TRANSACTION_ADD_KEY: QueryKey = [
  'cash-list-transactions-add',
];

export const CASH_TRANSACTION_GROUP_LIST_KEY: QueryKey = [
  'cash-transaction-group-list',
];
export const CASH_TRANSACTION_GROUP_KEY = (groupId: string): string[] => [
  `cash-transaction-group`,
  groupId,
];
export const MAKE_CASH_TRANSACTION_GROUP_LIST_KEY = (
  cashListId: string | undefined,
  params: object
): QueryKey => [...CASH_TRANSACTION_GROUP_LIST_KEY, cashListId, params];

export const CASH_LIST_TRANSACTIONS_KEY_FOR_DATE = (
  cashListId: string | undefined,
  dateFrom?: Date,
  dateTo?: Date
): QueryKey => [
  ...CASH_LIST_TRANSACTIONS_KEY,
  cashListId,
  {
    dateFrom,
    dateTo,
  },
];
export const invalidateCashList = async (
  client: QueryClient,
  nursingHomeId?: string
): Promise<void> => {
  await Promise.all([
    client.invalidateQueries({
      queryKey: CASH_LIST_CONFIGURATION_KEY(nursingHomeId),
    }),
  ]);
};
