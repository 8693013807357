import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type {
  FamilyMemberPhoenix,
  FamilyMemberResidentRelationship,
  Gender,
  GetAllResidentsResult,
  ImportFamilyMemberPhoenix,
  ImportFamilyMemberResponsePhoenix,
  ShowResidentDto,
} from '@pflegenavi/shared/api';

export interface CreateResidentResult {
  data: any;
}

export type InitialBalance =
  | {
      cash_list_id: string;
      notes?: string;
      coins: number[];
    }
  | {
      cash_list_id: string;
      notes?: string;
      bank_amount: number;
    }
  | {
      notes?: string;
      amount: number;
    };

export interface UpdateResidentFamilyMemberDto {
  user_id: string;
  first_name: string;
  last_name: string;
  notes?: string;
  phone?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  relationship_to_resident: FamilyMemberResidentRelationship;
}

export interface CreateResidentParams {
  body: {
    nursing_home_id: string;
    data: {
      entry_date: Date;
      firstname: string;
      lastname: string;
      gender: Gender;
      birthdate?: Date;
      resident_accounting_id?: string;
      initial_balance?: InitialBalance;
      service_providers?: Array<{
        global_service_provider_id: string;
      }>;
      family_member?: {
        first_name: string;
        last_name: string;
        email: string;
        notes?: string;
        phone?: string;
        address?: string;
        zip_code?: string;
        city?: string;
        country?: string;
        relationship_to_resident: FamilyMemberResidentRelationship | undefined;
      };
    };
  };
}

export interface KYCDocument {
  CreationDate: number;
  Flags: number[];
  Id: string;
  ProcessedDate: number | null;
  RefusedReasonMessage: string | null;
  RefusedReasonType:
    | 'DOCUMENT_DO_NOT_MATCH_USER_DATA'
    | 'DOCUMENT_FALSIFIED'
    | 'DOCUMENT_HAS_EXPIRED'
    | 'DOCUMENT_INCOMPLETE'
    | 'DOCUMENT_MISSING'
    | 'DOCUMENT_NOT_ACCEPTED'
    | 'DOCUMENT_UNREADABLE'
    | 'SPECIFIC_CASE'
    | 'UNDERAGE_PERSON'
    | null;
  Status:
    | 'CREATED'
    | 'VALIDATION_ASKED'
    | 'VALIDATED'
    | 'REFUSED'
    | 'OUT_OF_DATE';
  Tag: string | null;
  Type:
    | 'IDENTITY_PROOF'
    | 'REGISTRATION_PROOF'
    | 'ARTICLES_OF_ASSOCIATION'
    | 'SHAREHOLDER_DECLARATION'
    | 'ADDRESS_PROOF';
  SubType?: 'resident' | 'guardian';
  UserId: string;
}

export interface KycUser {
  Id: string;
  KYCLevel: 'LIGHT' | 'REGULAR';
  LegalRepresentativeProofOfIdentity: string | null;
  ProofOfRegistration: string | null;
  TermsAndConditionsAccepted: boolean;
  TermsAndConditionsAcceptedDate: number;
  UserCategory: 'PAYER' | 'OWNER';
}

export interface GetKycDocumentsResult {
  user: KycUser;
  kyc_documents: KYCDocument[];
}

export interface CreateKycDocumentParams {
  params: {
    residentId: string;
  };
  body: {
    Type:
      | 'IDENTITY_PROOF'
      | 'REGISTRATION_PROOF'
      | 'ARTICLES_OF_ASSOCIATION'
      | 'SHAREHOLDER_DECLARATION'
      | 'ADDRESS_PROOF';
    pages: string[];
    Tag?: string;
  };
}

export interface IResidentPhoenixApi extends Api {
  getAllResidents(dataIn: {
    params: { nursingHomeId: string };
  }): Promise<GetAllResidentsResult>;

  createResident(dataIn: CreateResidentParams): Promise<CreateResidentResult>;
  showResident(dataIn: {
    params: { residentId: string };
  }): Promise<ShowResidentDto>;
  showResidentForFamilyMember(dataIn: {
    params: { tenant: string; residentId: string };
  }): Promise<ShowResidentDto>;
  resendFamilyMemberWelcomeEmail(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<void>;

  importFamilyMembers(dataIn: {
    body: ImportFamilyMemberPhoenix;
  }): Promise<ImportFamilyMemberResponsePhoenix>;

  addNewFamilyMember(dataIn: {
    params: {
      residentId: string;
    };
    body: {
      data: FamilyMemberPhoenix;
    };
  }): Promise<{
    status: 'Created';
  }>;

  deleteFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  updateFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
    body: {
      data: UpdateResidentFamilyMemberDto;
    };
  }): Promise<{ data: UpdateResidentFamilyMemberDto }>;

  getKycDocuments(dataIn: {
    params: { residentId: string };
  }): Promise<GetKycDocumentsResult>;
  getKycDocument(dataIn: {
    params: {
      residentId: string;
      kycDocumentId: string;
    };
  }): Promise<KYCDocument>;
  createKycDocument(dataIn: CreateKycDocumentParams): Promise<KYCDocument>;
}

export class ResidentPhoenixApi implements IResidentPhoenixApi {
  baseUrl: string;

  getAllResidents: IResidentPhoenixApi['getAllResidents'];
  createResident: IResidentPhoenixApi['createResident'];
  showResident: IResidentPhoenixApi['showResident'];
  showResidentForFamilyMember: IResidentPhoenixApi['showResidentForFamilyMember'];
  resendFamilyMemberWelcomeEmail: IResidentPhoenixApi['resendFamilyMemberWelcomeEmail'];
  public importFamilyMembers: IResidentPhoenixApi['importFamilyMembers'];
  public addNewFamilyMember: IResidentPhoenixApi['addNewFamilyMember'];
  public deleteFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['deleteFamilyMemberForGivenResidentPhoenix'];
  public updateFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['updateFamilyMemberForGivenResidentPhoenix'];
  public getKycDocuments: IResidentPhoenixApi['getKycDocuments'];
  public getKycDocument: IResidentPhoenixApi['getKycDocument'];
  public createKycDocument: IResidentPhoenixApi['createKycDocument'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);
    const familyMemberBaseUrl = getPhoenixApiTenantUrl('family-member', apiUrl);

    this.getAllResidents = get({
      authContext,
      url: (params) => {
        return `${this.baseUrl}/resident?nursing_home_id=${params.nursingHomeId}`;
      },
    });

    this.createResident = modify({
      authContext,
      url: `${this.baseUrl}/resident`,
      method: 'POST',
    });

    this.showResident = get({
      authContext,
      url: (params) => `${this.baseUrl}/resident/${params.residentId}`,
    });

    this.showResidentForFamilyMember = get({
      authContext,
      url: (params) =>
        `${familyMemberBaseUrl}/${params.tenant}/${params.residentId}/resident-info`,
    });

    this.resendFamilyMemberWelcomeEmail = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}/resend_welcome`,
      method: 'POST',
    });

    this.importFamilyMembers = modify<
      ImportFamilyMemberPhoenix,
      ImportFamilyMemberResponsePhoenix
    >({
      authContext,
      url: `${this.baseUrl}/resident/family-member/import`,
      method: 'POST',
    });

    this.addNewFamilyMember = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member`,
      method: 'POST',
    });

    this.deleteFamilyMemberForGivenResidentPhoenix = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
        familyMemberId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'DELETE',
    });

    this.updateFamilyMemberForGivenResidentPhoenix = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'PUT',
    });

    this.getKycDocuments = get({
      authContext,
      headers: new Headers({
        'content-type': 'application/json',
        'x-pn-stripemode': 'live',
      }),
      url: (params) => `${this.baseUrl}/resident/${params.residentId}/kyc`,
    });

    this.getKycDocument = get({
      authContext,
      headers: new Headers({
        'content-type': 'application/json',
        'x-pn-stripemode': 'live',
      }),
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/kyc/${params.kycDocumentId}`,
    });

    const createHeaders = new Headers({
      'x-pn-stripemode': 'live',
    });

    this.createKycDocument = modify({
      authContext,
      headers: createHeaders,
      url: (params) => `${this.baseUrl}/resident/${params.residentId}/kyc`,
      transformBody: ({ Type, pages, Tag }) => {
        const formData = new FormData();
        formData.append('Type', Type);
        if (Tag) {
          formData.append('Tag', Tag);
        }
        for (const page of pages) {
          formData.append('pages[]', page);
        }
        return formData;
      },
      method: 'POST',
    });
  }
}
