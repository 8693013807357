import type { QueryKey } from '@tanstack/react-query';

export const GET_KYC_DOCUMENTS: QueryKey = ['GET_KYC_DOCUMENTS'];
export const GET_KYC_DOCUMENT: QueryKey = ['GET_KYC_DOCUMENT'];
export const CREATE_KYC_DOCUMENT: QueryKey = ['CREATE_KYC_DOCUMENT'];

export const RESIDENTS_PHOENIX_KEY = (nursingHomeId: string): QueryKey => [
  'residents-phoenix',
  nursingHomeId,
];

export const SHOW_RESIDENT_KEY = (residentId: string): QueryKey => [
  'show-resident',
  residentId,
];
export const ALL_SHOW_RESIDENT_KEY = (): QueryKey => ['show-resident'];
