import type {
  UploadFile,
  UploadState,
} from '@pflegenavi/shared-frontend/platform';
import {
  makeApiProvider,
  useApiInfiniteQuery,
  useApiQuery,
  usePrefetchApiQuery,
  useUploadFile,
} from '@pflegenavi/shared-frontend/platform';
import type {
  InfiniteData,
  UseInfiniteQueryResult,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  useMutation,
  useQueryClient,
  replaceEqualDeep,
} from '@tanstack/react-query';

import type {
  CancelManualDepositDto,
  CancelReceiptDto,
  CreateReceiptBatchJobAction,
  CreateReceiptDto,
  FilteredTransactionChangesResultDto,
  GetFilteredBalanceChangesQueryParams,
  GetReceiptBatchesQueryDto,
  GetTotalResidentBalanceQueryParams,
  GetTransactionsPaginatedQueryParams,
  GetTransactionsWithReceiptDto,
  IReceiptType,
  ManualDepositDto,
  PaginatedReceiptQueryDto,
  PaginatedReceiptResult,
  ReceiptBatchGetDto,
  ReceiptBatchListDto,
  ReceiptBatchPaginatedDto,
  ReceiptFull,
  ReceiptsAndReceiptBatchesDto,
  ReceiptsAndReceiptBatchesQueryParams,
  TotalResidentBalanceResultDto,
  TransactionSourceType,
  UpdateReceiptBatchCashTransactionGroupDto,
  UpdateReceiptBatchResultDto,
  UpdateTransactionDto,
} from '@pflegenavi/shared/api';
import { ReceiptStatusValues } from '@pflegenavi/shared/api';
import type {
  PaginatedResultSet,
  PaginatedResultSubset,
} from '@pflegenavi/shared/utils';
import isEqual from 'lodash.isequal';
import { createContext, useCallback, useMemo } from 'react';
import {
  CASH_LIST_CONFIGURATION_KEY,
  CASH_TRANSACTION_GROUP_KEY,
} from '../cash-management/queryKeys';
import { RECEIPT_BATCH_PAYMENT_STATUS } from '../payment';
import { invalidateAllResidentBalance } from '../reporting/queryKeys';
import { getFinanceKey } from '../reporting-phoenix';
import { usePrefetchResidents } from '../resident';
import {
  invalidateResident,
  invalidatePendingReceiptCount,
} from '../resident/queryKeys';
import { RESIDENTS_KEY } from '../resident/queryKeys';
import { usePrefetchServiceProviders } from '../service-provider';
import type { ITransactionApi } from './api';
import { TransactionApi } from './api';
import {
  CANCEL_RECEIPT_KEY,
  CREATE_RECEIPT_BATCH_JOB,
  CREATE_RECEIPT_KEY,
  DELETE_TRANSACTION_KEY,
  FILTERED_BALANCE_CHANGES_PERIOD_KEY,
  MANUAL_DEPOSIT_CANCELLATION_KEY,
  MANUAL_DEPOSIT_KEY,
  RECEIPTS_AND_RECEIPT_BATCHES_KEY,
  RECEIPTS_AND_RECEIPT_BATCHES_PREFIX,
  RECEIPTS_KEY,
  RECEIPTS_KEY_NURSING_HOME,
  RECEIPT_BATCH_KEY,
  RECEIPT_BATCH_LIST_KEY,
  RECEIPT_BATCH_LIST_PAGINATED_KEY,
  SUBMIT_RECEIPTS_KEY,
  TOTAL_BALANCE_PERIOD_KEY,
  TRANSACTIONS_PAGINATED_KEY,
  TRANSACTION_TYPES_KEY,
  UPDATE_RECEIPT_BATCH,
  UPDATE_TRANSACTION_KEY,
  getReceiptImagesKey,
  getReceiptKey,
  getTransactionsAsFamilyMemberKey,
  getTransactionsKey,
} from './queryKeys';
import { ALL_SHOW_RESIDENT_KEY } from '../resident-phoenix/queryKeys';

export {
  isReceiptBatchWithInactiveServiceProviderError,
  isReceiptWithInactiveServiceProviderError,
} from './api';

const ApiContext = createContext<ITransactionApi | undefined>(undefined);
const { useApi: useTransactionApi, ApiProvider: TransactionApiProvider } =
  makeApiProvider({
    name: 'Transaction',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new TransactionApi(tenantId, auth, apiUrl),
  });

export {
  ApiContext as TransactionApiContext,
  TransactionApiProvider,
  useTransactionApi,
};
export type { ITransactionApi, TransactionApi };
export function useReceiptsPaginated<TData = PaginatedReceiptResult>(
  params: Omit<PaginatedReceiptQueryDto, 'nursingHomeId'> & {
    nursingHomeId?: string;
  },
  options?: Omit<
    UseQueryOptions<PaginatedReceiptResult, unknown, TData>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<TData, unknown> {
  return useApiQuery(
    useTransactionApi,
    RECEIPTS_KEY_NURSING_HOME(params.nursingHomeId, params),
    (api) => {
      const { nursingHomeId, ...otherParams } = params;
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getPaginated({
        params: {
          ...otherParams,
          nursingHomeId,
        },
      });
    },
    {
      ...options,
      enabled: (options?.enabled ?? true) && params.nursingHomeId !== undefined,
      refetchOnWindowFocus: true,
    }
  );
}

export const usePrefetchFirstOpenReceipts = (
  nursingHomeId: string | undefined
): void => {
  const params = useMemo(
    () => ({
      nursingHomeId,
      pageSize: 50,
      page: 0,
      status: [ReceiptStatusValues.DRAFT, ReceiptStatusValues.INCOMPLETE],
    }),
    [nursingHomeId]
  );

  const queryFn = useCallback(
    (api: ITransactionApi) => {
      if (params.nursingHomeId === undefined) {
        throw new Error('nursingHomeId is required');
      }
      return api.getPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    [params]
  );

  return usePrefetchApiQuery(
    useTransactionApi,
    RECEIPTS_KEY_NURSING_HOME(nursingHomeId, params),
    queryFn,
    nursingHomeId !== undefined
  );
};

export const useFirstOpenReceipts = (
  nursingHomeId: string | undefined,
  options?: Omit<
    UseQueryOptions<PaginatedReceiptResult, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<PaginatedReceiptResult, unknown> => {
  const params = useMemo(
    () => ({
      nursingHomeId,
      pageSize: 50,
      page: 0,
      status: [ReceiptStatusValues.DRAFT, ReceiptStatusValues.INCOMPLETE],
    }),
    [nursingHomeId]
  );

  return useReceiptsPaginated(params, options);
};

export const useTotalBalancePeriod = (
  params: GetTotalResidentBalanceQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: Omit<
    UseQueryOptions<TotalResidentBalanceResultDto, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<TotalResidentBalanceResultDto, unknown> => {
  return useApiQuery(
    useTransactionApi,
    TOTAL_BALANCE_PERIOD_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getTotalBalancePeriod({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      enabled: params.nursingHomeId !== undefined,
      ...options,
    }
  );
};

export const useFilteredBalanceChangesPeriod = (
  params: GetFilteredBalanceChangesQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: Omit<
    UseQueryOptions<FilteredTransactionChangesResultDto, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<FilteredTransactionChangesResultDto, unknown> => {
  return useApiQuery(
    useTransactionApi,
    FILTERED_BALANCE_CHANGES_PERIOD_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getFilteredBalanceChangesPeriod({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      enabled: params.nursingHomeId !== undefined,
      ...options,
    }
  );
};

const selectTransactionsPaginated = (
  data: PaginatedResultSet<GetTransactionsWithReceiptDto>
): PaginatedResultSet<GetTransactionsWithReceiptDto> => {
  return {
    ...data,
    computeAndSetLinks: data.computeAndSetLinks,
    data: data.data.map((row) =>
      mapGetTransactionWithReceiptResponseToDto(row)
    ),
  };
};

export const useTransactionsPaginated = (
  params: GetTransactionsPaginatedQueryParams & {
    nursingHomeId: string | undefined;
  },
  options?: Omit<
    UseQueryOptions<PaginatedResultSet<GetTransactionsWithReceiptDto>, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<
  PaginatedResultSet<GetTransactionsWithReceiptDto>,
  unknown
> => {
  return useApiQuery(
    useTransactionApi,
    TRANSACTIONS_PAGINATED_KEY(params),
    (api) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getTransactionsPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      staleTime: 0,
      enabled: params.nursingHomeId !== undefined,
      select: selectTransactionsPaginated,
      ...options,
    }
  );
};

export const useGetTransactionsPaginatedCallback: () => (
  params: GetTransactionsPaginatedQueryParams & {
    nursingHomeId: string | undefined;
  }
) => Promise<PaginatedResultSet<GetTransactionsWithReceiptDto>> = () => {
  const api = useTransactionApi();
  return useCallback(
    async (
      params: GetTransactionsPaginatedQueryParams & {
        nursingHomeId: string | undefined;
      }
    ) => {
      if (!params.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      const result = await api.getTransactionsPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });

      return selectTransactionsPaginated(result);
    },
    [api]
  );
};

const mapGetTransactionWithReceiptResponseToDto = (
  responseElement: GetTransactionsWithReceiptDto
): GetTransactionsWithReceiptDto => {
  return {
    ...responseElement,
    date: new Date(responseElement.date),
    receiptDate: responseElement.receiptDate
      ? new Date(responseElement.receiptDate)
      : undefined,
    cancelledSubmissionDate: responseElement.cancelledSubmissionDate
      ? new Date(responseElement.cancelledSubmissionDate)
      : undefined,
  };
};

const makeTransactionQueryOptions = (
  sourceTypes: readonly TransactionSourceType[],
  options?: Omit<
    UseQueryOptions<GetTransactionsWithReceiptDto[], unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryOptions<GetTransactionsWithReceiptDto[], unknown> => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const select = useCallback(
    (
      data: GetTransactionsWithReceiptDto[]
    ): GetTransactionsWithReceiptDto[] => {
      if (!data) {
        return [];
      }
      let response;
      if (sourceTypes.length > 0) {
        response = data.filter((row) => sourceTypes?.includes(row.sourceType));
      } else {
        response = data;
      }
      return response.map((row) =>
        mapGetTransactionWithReceiptResponseToDto(row)
      );
    },
    [sourceTypes]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDataEqual = useCallback(
    (
      oldData: GetTransactionsWithReceiptDto[] | undefined,
      newData: GetTransactionsWithReceiptDto[]
    ) => {
      return oldData ? oldData.length === newData.length : false;
    },
    []
  );

  return {
    // Taking advantage of transactions being append only - e.g. there can't be any changes in-between
    // @ts-expect-error // structuralSharing expects "unknown"
    structuralSharing: (
      oldData: GetTransactionsWithReceiptDto[] | undefined,
      newData: GetTransactionsWithReceiptDto[]
    ) => {
      const shouldKeepOld = isDataEqual(oldData, newData);
      return shouldKeepOld ? oldData : replaceEqualDeep(oldData, newData);
    },
    select: select,
    ...options,
  };
};

export const useTransactionsForFamilyMember = (
  residentId: string | undefined,
  sourceTypes: readonly TransactionSourceType[] = [],
  familyMemberId?: string | null,
  options?: Omit<
    UseQueryOptions<GetTransactionsWithReceiptDto[], unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<GetTransactionsWithReceiptDto[], unknown> => {
  const transactionQueryOptions = makeTransactionQueryOptions(sourceTypes, {
    ...options,
    enabled:
      options?.enabled === undefined
        ? residentId !== undefined
        : options?.enabled && residentId !== undefined,
  });
  const api = useTransactionApi(true);
  return useApiQuery(
    () => api,
    getTransactionsAsFamilyMemberKey(
      residentId ?? 'undefined',
      undefined,
      familyMemberId
    ),
    (api) => {
      if (!residentId || !api) {
        throw new Error('residentId is undefined');
      }
      return api.getTransactionsAsFamilyMember(
        residentId,
        undefined,
        familyMemberId
      );
    },
    transactionQueryOptions
  );
};

export const useIncompleteReceiptCount = (
  nursingHomeId: string | undefined
): UseQueryResult<number, unknown> => {
  return useReceiptsPaginated(
    {
      nursingHomeId,
      page: 0,
      pageSize: 1,
      status: [ReceiptStatusValues.INCOMPLETE, ReceiptStatusValues.DRAFT],
    },
    {
      select: (data) => data?.meta?.totalItems ?? 0,
    }
  );
};

export const useUnsubmittedReceiptBatchCount = (
  nursingHomeId: string | undefined
): UseQueryResult<number, unknown> => {
  return useGetReceiptBatchListPaginated(
    {
      nursingHomeId,
      page: 0,
      pageSize: 1,
      state: null, // Only unsubmitted receipts
    },
    {
      select: (data) => data?.meta?.totalItems ?? 0,
    }
  );
};

export const usePrefetchTransactionTypes = (): void => {
  const getTypes = useCallback((api: ITransactionApi) => {
    return api.getTypes();
  }, []);
  return usePrefetchApiQuery(
    useTransactionApi,
    TRANSACTION_TYPES_KEY,
    getTypes
  );
};

export const useReceipt = (
  transactionId: string,
  nursingHomeId: string | undefined,
  queryOptions?: {
    optionalFields?: {
      linkedTransactionGroups?: boolean;
      receiptBatch?: boolean;
    };
  },
  options?: Omit<UseQueryOptions<ReceiptFull, unknown>, 'queryFn' | 'queryKey'>
): UseQueryResult<ReceiptFull, unknown> => {
  usePrefetchResidents(nursingHomeId);
  usePrefetchTransactionTypes();
  usePrefetchServiceProviders(nursingHomeId);

  const select = useCallback((data: ReceiptFull): ReceiptFull => {
    return {
      ...data,
      date: new Date(data.date),
      created_on: new Date(data.created_on),
      submissionDate: data.submissionDate
        ? new Date(data.submissionDate)
        : undefined,
    };
  }, []);

  return useApiQuery(
    useTransactionApi,
    getReceiptKey(transactionId, queryOptions?.optionalFields),
    (api) => api.getOne(transactionId, queryOptions),
    {
      select,
      ...options,
    }
  );
};

export const useCreateReceipt = (): UseMutationResult<
  ReceiptFull,
  unknown,
  CreateReceiptDto
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<ReceiptFull, unknown, CreateReceiptDto>({
    mutationKey: CREATE_RECEIPT_KEY,
    mutationFn: (data) => api.create(data),
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({ queryKey: RECEIPTS_KEY }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_PREFIX,
        }),
        invalidatePendingReceiptCount(queryClient),
      ]);
    },
  });
  return result;
};

interface UpdateTransactionData extends UpdateTransactionDto {
  groupId?: string;
}

export const useUpdateTransaction = (queryInvalidationParams?: {
  nursingHomeId?: string;
}): UseMutationResult<Partial<ReceiptFull>, unknown, UpdateTransactionData> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    Partial<ReceiptFull>,
    unknown,
    UpdateTransactionData,
    {
      previousReceipt?: ReceiptFull;
    }
  >({
    mutationKey: UPDATE_TRANSACTION_KEY,
    mutationFn: (data) => api.update(data),
    onMutate: async (data) => {
      await queryClient.cancelQueries({
        queryKey: getReceiptKey(data.receipt_id),
      });

      const previousReceipt = queryClient.getQueryData<ReceiptFull>([
        RECEIPTS_KEY,
        data.receipt_id,
      ]);

      if (!previousReceipt) {
        return { previousReceipt };
      }

      const newReceipt = { ...previousReceipt, ...data };

      const nullable = [
        'date',
        'resident_id',
        'amount',
        'amountInCent',
        'notes',
        'receipt_type_id',
        'service_provider',
      ] as const;
      for (const key of nullable) {
        if (newReceipt[key] === null) {
          delete newReceipt[key];
        }
      }

      queryClient.setQueryData(getReceiptKey(data.receipt_id), newReceipt);

      return { previousReceipt };
    },
    onError: (err, newTodo, context) => {
      if (context) {
        queryClient.setQueryData(
          getReceiptKey(newTodo.receipt_id),
          context.previousReceipt
        );
      }
    },
    onSuccess: async ({ id }, { groupId }) => {
      if (groupId) {
        await queryClient.invalidateQueries({
          queryKey: CASH_TRANSACTION_GROUP_KEY(groupId),
        });
      }
      if (id) {
        await queryClient.invalidateQueries({ queryKey: getReceiptKey(id) });
      }

      await queryClient.invalidateQueries({
        queryKey: RECEIPTS_KEY_NURSING_HOME(
          queryInvalidationParams?.nursingHomeId
        ),
      });
      await queryClient.invalidateQueries({
        queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(
          queryInvalidationParams?.nursingHomeId
        ),
      });
      return await queryClient.invalidateQueries({ queryKey: RECEIPTS_KEY });
    },
  });
  return result;
};

export const useCancelReceipt = (
  receiptId: string,
  nursingHomeId?: string
): UseMutationResult<void, unknown, CancelReceiptDto> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, CancelReceiptDto>({
    mutationKey: CANCEL_RECEIPT_KEY(receiptId),
    mutationFn: (data) => api.cancel(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: getReceiptKey(receiptId),
      });
      await queryClient.invalidateQueries({
        queryKey: RESIDENTS_KEY(nursingHomeId),
      });
      await invalidateAllResidentBalance(queryClient, nursingHomeId);
      return await queryClient.invalidateQueries({
        queryKey: CANCEL_RECEIPT_KEY(receiptId),
      });
    },
  });
};

export const useReceiptTypes = (
  options?: Omit<
    UseQueryOptions<IReceiptType[], unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<IReceiptType[], unknown> => {
  return useApiQuery(
    useTransactionApi,
    TRANSACTION_TYPES_KEY,
    (api) => api.getTypes(),
    options
  );
};

export const useDeleteReceipt = (invalidationData?: {
  nursingHomeId?: string;
}): {
  deleteTransaction: UseMutationResult<void, unknown, string, unknown>;
} => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const deleteTransaction = useMutation({
    mutationKey: DELETE_TRANSACTION_KEY,
    mutationFn: (id: string) => api.delete(id),
    onSettled: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_KEY_NURSING_HOME(invalidationData?.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(
            invalidationData?.nursingHomeId
          ),
        }),
      ]);
    },
  });
  return {
    deleteTransaction,
  };
};

export const useSubmitReceipts = (): {
  submitReceipts: UseMutationResult<
    Array<{
      id: string;
    }>,
    unknown,
    {
      receiptIds: string[];
      nursingHomeId?: string;
    },
    unknown
  >;
} => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const submitReceipts = useMutation<
    Array<{
      id: string;
    }>,
    unknown,
    {
      receiptIds: string[];
      nursingHomeId?: string;
    },
    unknown
  >({
    mutationKey: SUBMIT_RECEIPTS_KEY,
    mutationFn: (params) => api.submit(params.receiptIds),
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ALL_SHOW_RESIDENT_KEY() }),
        queryClient.invalidateQueries({ queryKey: RECEIPTS_KEY }),
        queryClient.invalidateQueries({
          queryKey: getFinanceKey(params.nursingHomeId),
        }),
        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        queryClient.invalidateQueries({
          queryKey: RESIDENTS_KEY(params.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(params.nursingHomeId),
        }),
      ]);
    },
  });
  return {
    submitReceipts,
  };
};

export const useReceiptImages = (
  receiptImageIds?: string[]
): UseQueryResult<
  | {
      [key: string]: string | undefined;
    }
  | undefined,
  unknown
> => {
  return useApiQuery(
    useTransactionApi,
    getReceiptImagesKey(receiptImageIds?.toString()),
    (api) => {
      if (receiptImageIds && receiptImageIds?.length > 0) {
        return api.getReceiptImageUrls(receiptImageIds);
      }
      return Promise.resolve({});
    },
    {
      enabled: receiptImageIds !== undefined, // && receiptImageIds.length !== 0,
      gcTime: 0,
      refetchOnWindowFocus: false,
      structuralSharing: (oldData, newData) =>
        isEqual(oldData, newData)
          ? oldData
          : replaceEqualDeep(oldData, newData),
    }
  );
};

const RECEIPT_IMAGE_URLS_AND_TYPE_KEY = (imageIds?: string[]) => [
  'RECEIPT_IMAGE_URLS_AND_TYPE_KEY',
  ...(imageIds ?? []),
];
export const useReceiptImageUrlsAndType = (
  imageIds?: string[]
): UseQueryResult<
  Array<{
    imageId: string;
    imageUri?: string;
    previewUri?: string;
    fileType?: string;
  }>,
  unknown
> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPT_IMAGE_URLS_AND_TYPE_KEY(imageIds),
    async (api) => {
      if (imageIds && imageIds?.length > 0) {
        const imageIdsToUris = await api.getReceiptImageUrls(imageIds);
        const imageIdsToTypes = await Promise.all(
          imageIds.map(async (imageId) => {
            const imageUri = imageIdsToUris?.[imageId];
            if (!imageUri) {
              return {
                imageId,
                imageUri: undefined,
                previewUri: undefined,
                fileType: undefined,
              };
            }

            const response = await fetch(imageUri);
            const extractedFileType =
              response.headers.get('content-type') ?? 'type-unknown';

            return {
              imageId,
              imageUri,
              previewUri: imageUri,
              fileType: extractedFileType,
            };
          })
        );

        return imageIdsToTypes;
      }
      return undefined;
    },
    {
      enabled: imageIds !== undefined && imageIds.length > 0,
    }
  );
};

export const useFetchReceiptImages = (): {
  fetchReceiptImages: (imagesIds: string[]) => Promise<
    | {
        [p: string]: string | undefined;
      }
    | undefined
  >;
} => {
  const client = useQueryClient();
  const api = useTransactionApi();
  const fetchReceiptImages = useCallback(
    (imagesIds: string[]) => {
      return client.fetchQuery({
        queryKey: getReceiptImagesKey(imagesIds.toString()),
        queryFn: () => api.getReceiptImageUrls(imagesIds),
        gcTime: 0,
        structuralSharing: (oldData, newData) =>
          isEqual(oldData, newData)
            ? oldData
            : replaceEqualDeep(oldData, newData),
      });
    },
    [client, api]
  );
  return { fetchReceiptImages };
};

export const useUploadReceiptImage: (
  uuid: () => string,
  computeMd5Hash: (file: File) => Promise<string>
) => {
  upload: UploadFile;
  uploadState: UploadState | undefined;
} = (uuid, computeMd5Hash) => {
  const api = useTransactionApi();

  const { upload, uploadState } = useUploadFile({
    uuid,
    getUploadUrl: api.getUploadUrl.bind(api),
    computeMd5Hash,
  });
  return {
    upload,
    uploadState,
  };
};

export const useGetUploadUrl: () => (
  receiptId: string,
  fileType: string,
  md5Hash: string
) => Promise<string | undefined> = () => {
  const api = useTransactionApi();
  return useCallback(
    (receiptId: string, fileType: string, md5Hash: string) => {
      return api.getUploadUrl(receiptId, fileType, md5Hash);
    },
    [api]
  );
};

export const useCreateManualDeposit = (): UseMutationResult<
  void,
  unknown,
  {
    data: ManualDepositDto;
    nursingHomeId?: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      data: ManualDepositDto;
      nursingHomeId?: string;
    }
  >({
    mutationKey: MANUAL_DEPOSIT_KEY,
    mutationFn: (params) => api.manualDeposit(params.data),
    onSuccess: (_, params) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: getTransactionsKey(params.data.resident_id),
        }),
        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        invalidateResident(
          queryClient,
          params.data.resident_id,
          params.nursingHomeId
        ),
        queryClient.invalidateQueries({
          queryKey: TRANSACTIONS_PAGINATED_KEY({
            nursingHomeId: params.nursingHomeId,
          }),
        }),
      ]);
    },
  });
};

export const useCancelManualDeposit = (): UseMutationResult<
  void,
  unknown,
  {
    transactionId: string;
    residentId: string;
    cashTransaction?: CancelManualDepositDto;
    nursingHomeId?: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      transactionId: string;
      residentId: string;
      cashTransaction?: CancelManualDepositDto;
      nursingHomeId?: string;
    }
  >({
    mutationKey: MANUAL_DEPOSIT_CANCELLATION_KEY,
    mutationFn: ({ transactionId, cashTransaction }) =>
      api.cancelManualDeposit({
        transactionId,
        cashTransaction,
      }),
    onSuccess: (_, data) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: getTransactionsKey(data.residentId),
        }),
        invalidateResident(queryClient, data.residentId, data.nursingHomeId),
        invalidateAllResidentBalance(queryClient, data.nursingHomeId),
        queryClient.invalidateQueries({
          queryKey: RESIDENTS_KEY(data.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: TRANSACTIONS_PAGINATED_KEY({
            nursingHomeId: data.nursingHomeId,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: CASH_LIST_CONFIGURATION_KEY(data.nursingHomeId),
        }),
      ]);
    },
  });
};

export const useUpdateReceiptBatchCashTransactionGroup = (): UseMutationResult<
  UpdateReceiptBatchResultDto,
  unknown,
  UpdateReceiptBatchCashTransactionGroupDto & {
    id: string;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    UpdateReceiptBatchResultDto,
    unknown,
    UpdateReceiptBatchCashTransactionGroupDto & {
      id: string;
    }
  >({
    mutationKey: UPDATE_RECEIPT_BATCH,
    mutationFn: (data) =>
      api.updateReceiptBatchCashTransactionGroup({
        body: data,
        params: { batchId: data.id },
      }),
    onSuccess: (data) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_LIST_KEY(data.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_LIST_PAGINATED_KEY({
            nursingHomeId: data.nursingHomeId,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(data.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_KEY(data.id),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_PAYMENT_STATUS(data.id),
        }),
        queryClient.invalidateQueries({
          queryKey: CASH_TRANSACTION_GROUP_KEY(data.id),
        }),
      ]);
    },
  });
  return result;
};

export const useGetReceiptBatchList = (
  nursingHomeId: string | undefined,
  options: Omit<
    UseQueryOptions<ReceiptBatchListDto[], unknown>,
    'queryFn' | 'queryKey'
  > = {}
): UseQueryResult<ReceiptBatchListDto[], unknown> => {
  const select = useCallback(
    (data: ReceiptBatchListDto[]): ReceiptBatchListDto[] => {
      const result = data.map((batch) => ({
        ...batch,
        receiptImageIds: batch.receiptImageIds ?? [],
        receiptDate: batch.receiptDate
          ? new Date(batch.receiptDate)
          : undefined,
        submissionDate: batch.submissionDate
          ? new Date(batch.submissionDate)
          : undefined,
        minReceiptDate: batch.minReceiptDate
          ? new Date(batch.minReceiptDate)
          : undefined,
        maxReceiptDate: batch.maxReceiptDate
          ? new Date(batch.maxReceiptDate)
          : undefined,
      }));

      return options?.select ? options.select(result) : result;
    },
    [options]
  );

  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_LIST_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw Error('nursingHomeId is undefined');
      }

      return api.getReceiptBatchList({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
      ...options,
      select,
    }
  );
};

export function useGetReceiptBatchListPaginated<
  TData = PaginatedResultSubset<ReceiptBatchPaginatedDto>
>(
  params: GetReceiptBatchesQueryDto & {
    nursingHomeId: string | undefined;
  },
  options: Omit<
    UseQueryOptions<
      PaginatedResultSubset<ReceiptBatchPaginatedDto>,
      unknown,
      TData
    >,
    'queryKey' | 'queryFn'
  > = {}
): UseQueryResult<TData, unknown> {
  const select = useCallback(
    (data: PaginatedResultSubset<ReceiptBatchPaginatedDto>): TData => {
      const result = {
        ...data,

        data: data.data.map((batch) => ({
          ...batch,
          receiptImageIds: batch.receiptImageIds ?? [],
          receiptDate: batch.receiptDate
            ? new Date(batch.receiptDate)
            : undefined,
          submissionDate: batch.submissionDate
            ? new Date(batch.submissionDate)
            : undefined,
          minReceiptDate: batch.minReceiptDate
            ? new Date(batch.minReceiptDate)
            : undefined,
          maxReceiptDate: batch.maxReceiptDate
            ? new Date(batch.maxReceiptDate)
            : undefined,
        })),
      };

      // @ts-expect-error // type depends on if options.select is passed or not. Hard to type correctly
      return options?.select ? options.select(result) : result;
    },
    [options]
  );
  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_LIST_PAGINATED_KEY(params),
    (api) => {
      if (params.nursingHomeId === undefined) {
        throw Error('nursingHomeId is undefined');
      }
      if (!api) {
        throw Error('api is undefined');
      }
      return api.getReceiptBatchListPaginated({
        params: {
          ...params,
          nursingHomeId: params.nursingHomeId,
        },
      });
    },
    {
      ...options,
      select,
      enabled: options.enabled ?? params.nursingHomeId !== undefined,
    }
  );
}

export const useGetFirstOpenReceiptBatches = (
  nursingHomeId: string | undefined,
  options?: Omit<
    UseQueryOptions<PaginatedResultSubset<ReceiptBatchPaginatedDto>, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<PaginatedResultSubset<ReceiptBatchPaginatedDto>, unknown> => {
  const params = useMemo<
    GetReceiptBatchesQueryDto & {
      nursingHomeId: string | undefined;
    }
  >(
    () => ({
      page: 0,
      pageSize: 50,
      nursingHomeId,
      state: null,
      sort: 'createdOn',
      sortOrder: 'desc',
    }),
    [nursingHomeId]
  );

  return useGetReceiptBatchListPaginated(params, options);
};

export const useGetReceiptsAndReceiptBatchesInfiniteQuery = ({
  nursingHomeId,
  ...params
}: Omit<ReceiptsAndReceiptBatchesQueryParams, 'page'> & {
  nursingHomeId: string | undefined;
}): UseInfiniteQueryResult<
  InfiniteData<PaginatedResultSubset<ReceiptsAndReceiptBatchesDto>>,
  unknown
> => {
  return useApiInfiniteQuery(
    useTransactionApi,
    RECEIPTS_AND_RECEIPT_BATCHES_KEY(nursingHomeId, params),
    (api, page) => {
      if (nursingHomeId === undefined) {
        throw new Error('nursingHomeId is undefined');
      }
      return api.getReceiptsAndReceiptBatchesPaginated({
        params: {
          nursingHomeId,
          page,
          ...params,
        },
      });
    },
    {
      enabled: nursingHomeId !== undefined,
      select: (
        data
      ): InfiniteData<PaginatedResultSubset<ReceiptsAndReceiptBatchesDto>> => {
        return {
          ...data,
          pages: [
            ...data.pages.map((page) => ({
              ...page,
              data: page.data.map((item) => ({
                ...item,
                createdAt: new Date(item.createdAt),
                submittedAt: item.submittedAt
                  ? new Date(item.submittedAt)
                  : undefined,
                receiptDate: item.receiptDate
                  ? new Date(item.receiptDate)
                  : undefined,
                ...(item.type === 'receipt-batch'
                  ? {
                      minReceiptDate: item.minReceiptDate
                        ? new Date(item.minReceiptDate)
                        : undefined,
                      maxReceiptDate: item.maxReceiptDate
                        ? new Date(item.maxReceiptDate)
                        : undefined,
                    }
                  : {
                      minReceiptDate: undefined,
                      maxReceiptDate: undefined,
                    }),
              })),
            })),
          ],
        };
      },
    }
  );
};

const getReceiptBatchSelect = (data: ReceiptBatchGetDto) => ({
  ...data,
  receiptDate: data.receiptDate ? new Date(data.receiptDate) : undefined,
  cashListTransactionGroupData: data.cashListTransactionGroupData
    ? {
        ...data.cashListTransactionGroupData,
        updateDate: new Date(data.cashListTransactionGroupData.updateDate),
      }
    : undefined,
  submissionDate: data.submissionDate
    ? new Date(data.submissionDate)
    : undefined,
  receiptImageIds: data.receiptImageIds ?? [],
  receiptBatchEntries: data.receiptBatchEntries.map((entry) => ({
    ...entry,
    receiptDate: entry.receiptDate ? new Date(entry.receiptDate) : undefined,
    receiptImageIds: entry.receiptImageIds ?? [],
  })),
});

export const useGetReceiptBatchCallback: () => (
  batchId: string
) => Promise<ReceiptBatchGetDto> = () => {
  const api = useTransactionApi();
  return useCallback(
    async (batchId: string) => {
      const result = await api.getReceiptBatch({ params: { batchId } });
      return getReceiptBatchSelect(result);
    },
    [api]
  );
};

export const useGetReceiptBatch = (
  batchId?: string
): UseQueryResult<ReceiptBatchGetDto, unknown> => {
  return useApiQuery(
    useTransactionApi,
    RECEIPT_BATCH_KEY(batchId),
    (api) => {
      if (!batchId) {
        throw Error('batchId is undefined');
      }
      return api.getReceiptBatch({ params: { batchId } });
    },
    {
      enabled: batchId !== undefined,
      select: getReceiptBatchSelect,
    }
  );
};
export const useCreateReceiptBatchJob = (): UseMutationResult<
  {
    id: string;
  },
  unknown,
  {
    batchId: string;
    action?: CreateReceiptBatchJobAction;
    nursingHomeId: string | undefined;
  }
> => {
  const api = useTransactionApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      id: string;
    },
    unknown,
    {
      batchId: string;
      action?: CreateReceiptBatchJobAction;
      nursingHomeId: string | undefined;
    }
  >({
    mutationKey: CREATE_RECEIPT_BATCH_JOB,
    mutationFn: (data) =>
      api.createReceiptBatchJob({
        params: { receiptBatchId: data.batchId },
        body: {
          action: data.action,
        },
      }),
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_LIST_KEY(variables.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_LIST_PAGINATED_KEY({
            nursingHomeId: variables.nursingHomeId,
          }),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_KEY(variables.batchId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPT_BATCH_PAYMENT_STATUS(variables.batchId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(variables.nursingHomeId),
        }),
      ]);
    },
  });
  return result;
};
