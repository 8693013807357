import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { INursingHomePhoenixApi } from './api';
import { NursingHomePhoenixApi } from './api';
import type { BaseData, LegalEntity } from '@pflegenavi/shared/api';
import { useAuthentication } from '@pflegenavi/shared-frontend/authentication';

const ApiContext = createContext<INursingHomePhoenixApi | undefined>(undefined);
const {
  useApi: useNursingHomePhoenixApi,
  ApiProvider: NursingHomePhoenixApiProvider,
} = makeApiProvider({
  name: 'NursingHomePhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) => {
    return new NursingHomePhoenixApi(tenantId, auth, apiUrl);
  },
});

export {
  useNursingHomePhoenixApi,
  NursingHomePhoenixApiProvider,
  ApiContext as NursingHomePhoenixApiContext,
};

const LEGAL_ENTITIES_KEY: QueryKey = ['LEGAL_ENTITIES_KEY'];
const CREATE_LEGAL_ENTITY_KEY: QueryKey = ['CREATE_LEGAL_ENTITY_KEY'];
const UPDATE_LEGAL_ENTITY_KEY: QueryKey = ['UPDATE_LEGAL_ENTITY_KEY'];

export const useGetPaymentProcessor = (): 'stripe' | 'mangopay' => {
  const auth = useAuthentication(true);
  return auth.paymentProcessor ?? 'stripe';
};

export const useGetLegalEntities = (
  options?: Omit<
    UseQueryOptions<
      {
        meta: {
          total_count: number;
        };
        data: LegalEntity[];
      },
      unknown,
      {
        meta: {
          total_count: number;
        };
        data: LegalEntity[];
      }
    >,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<
  {
    meta: {
      total_count: number;
    };
    data: LegalEntity[];
  },
  unknown
> => {
  return useApiQuery(
    useNursingHomePhoenixApi,
    LEGAL_ENTITIES_KEY,
    (api) => {
      return api.getLegalEntities();
    },
    {
      ...options,
    }
  );
};

export const useCreateLegalEntityPhoenix = (): UseMutationResult<
  {
    data: LegalEntity;
  },
  unknown,
  BaseData
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: LegalEntity;
    },
    unknown,
    BaseData
  >({
    mutationKey: CREATE_LEGAL_ENTITY_KEY,
    mutationFn: (base_data) =>
      api.createLegalEntity({
        body: {
          data: {
            base_data,
          },
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: LEGAL_ENTITIES_KEY });
    },
  });
  return result;
};

export const useUpdateLegalEntityPhoenix = ({
  legalEntityId,
}: {
  legalEntityId: string;
}): UseMutationResult<
  {
    data: LegalEntity;
  },
  unknown,
  BaseData
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      data: LegalEntity;
    },
    unknown,
    BaseData
  >({
    mutationKey: UPDATE_LEGAL_ENTITY_KEY,
    mutationFn: (base_data) =>
      api.updateLegalEntity({
        params: {
          legalEntityId,
        },
        body: {
          data: {
            id: legalEntityId,
            base_data,
          },
        },
      }),

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: LEGAL_ENTITIES_KEY });
    },
  });
  return result;
};

const DELETE_LEGAL_ENTITY_CONFLICT_ERROR_MESSAGE =
  'Legal entity is still associated with nursing homes';

export const isDeleteLegalEntityConflictException = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): boolean => {
  return (
    'errors' in error &&
    'detail' in error.errors &&
    error.errors.detail &&
    error.errors.detail === DELETE_LEGAL_ENTITY_CONFLICT_ERROR_MESSAGE
  );
};

export const useDeleteLegalEntityPhoenix = (): UseMutationResult<
  {
    success: boolean;
  },
  unknown,
  {
    legalEntityId: string;
  }
> => {
  const api = useNursingHomePhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    {
      success: boolean;
    },
    unknown,
    {
      legalEntityId: string;
    }
  >({
    mutationKey: ['UPDATE_LEGAL_ENTITY'],
    mutationFn: ({ legalEntityId }) =>
      api.deleteLegalEntity({
        params: {
          legalEntityId,
        },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: LEGAL_ENTITIES_KEY });
    },
  });
  return result;
};
