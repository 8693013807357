import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { QueryKey, UseQueryResult } from '@tanstack/react-query';
import type {
  Analytics,
  IReceiptsByStatus,
  ResidentSummaryDto,
} from '@pflegenavi/shared/api';
import { createContext, useCallback } from 'react';
import type { IAnalyticsApi } from './api';
import { AnalyticsApi } from './api';

const ApiContext = createContext<IAnalyticsApi | undefined>(undefined);
const { useApi: useAnalyticsApi, ApiProvider: AnalyticsApiProvider } =
  makeApiProvider({
    name: 'Analytics',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new AnalyticsApi(tenantId, auth, apiUrl),
  });

export { useAnalyticsApi, AnalyticsApiProvider };

const ANALYTICS_KEY = (nursingHomeId: string | undefined) => [
  'analytics',
  nursingHomeId,
];
export const ANALYTICS_RESIDENT_SUMMARY = (
  residentId: string | undefined
): QueryKey => [`analytics-resident-summary`, residentId];
const ANALYTICS_RECEIPT_BY_STATUS_KEY = (residentId: string, month?: Date) => [
  'analytics-receipt-by-status',
  residentId,
  {
    month,
  },
];

export const useAnalytics = (
  nursingHomeId: string | undefined
): UseQueryResult<Analytics, unknown> => {
  return useApiQuery(
    useAnalyticsApi,
    ANALYTICS_KEY(nursingHomeId),
    (api) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.get({ params: { nursingHomeId } });
    },
    {
      enabled: nursingHomeId !== undefined,
    }
  );
};

export const useResidentSummary = (
  residentId: string | undefined,
  familyMemberId?: string | null
): UseQueryResult<ResidentSummaryDto, unknown> => {
  const select = useCallback((result: ResidentSummaryDto) => {
    const { resident, latestPayments, latestTransactions } = result;
    return {
      resident: resident
        ? {
            ...resident,
            moveInDate: new Date(resident?.moveInDate),
            createdDate: new Date(resident?.createdDate),
          }
        : undefined,
      latestPayments: latestPayments.map((payment) => ({
        ...payment,
        date: new Date(payment.date),
        receiptDate: payment.receiptDate
          ? new Date(payment.receiptDate)
          : undefined,
        cancelledSubmissionDate: payment.cancelledSubmissionDate
          ? new Date(payment.cancelledSubmissionDate)
          : undefined,
      })),
      latestTransactions: latestTransactions.map((transaction) => ({
        ...transaction,
        date: new Date(transaction.date),
        receiptDate: transaction.receiptDate
          ? new Date(transaction.receiptDate)
          : undefined,
        cancelledSubmissionDate: transaction.cancelledSubmissionDate
          ? new Date(transaction.cancelledSubmissionDate)
          : undefined,
      })),
    };
  }, []);
  return useApiQuery(
    useAnalyticsApi,
    ANALYTICS_RESIDENT_SUMMARY(residentId),
    async (api) => {
      if (residentId === undefined) {
        throw new Error('Resident ID is undefined');
      }

      return await api.getResidentSummary({
        params: { residentId, familyMemberId },
      });
    },
    {
      select,
      enabled: Boolean(residentId),
    }
  );
};

export const useReceiptDataByStatusForResident = (
  residentId: string,
  month?: Date
): UseQueryResult<IReceiptsByStatus, unknown> => {
  return useApiQuery(
    useAnalyticsApi,
    ANALYTICS_RECEIPT_BY_STATUS_KEY(residentId, month),
    (api) => api.getByResidentId({ params: { residentId, month } })
  );
};
